<template>
  <div class="container">
    <!-- 顶部 -->
    <div class="header">
        <i class="back el-icon-arrow-left" @click="goBack"></i>
        提现
    </div>
    <div class="main">
        <!-- 银行信息 -->
        <div class="withdrawal-bank">
            <p>提现到</p>
            <div class="bank">
                <div class="bankMsg">
                    <img src="@/assets/images/hongbao/Group 2417.png" alt="">
                    <p class="inline-block">尾号  {{ userBankInfo.BankCardNo|encryption }} 储蓄卡</p>
                </div>
            </div>
            <p class="tips" style="padding-left:28px">预计2小时内到账</p>
        </div>
        <!-- 金额 -->
        <div class="withdrawal-money">
            <div class="title">提现金额</div>
            <div class="input" @click="changeMoney">
                <span>￥</span>
                <el-input v-model="money" readonly placeholder="" ref="moneyRef"></el-input>
            </div>
            <div class="tips">当前可提现余额：￥{{ maxAmount/ 100}}</div>
        </div>
        <!-- 测试键盘 -->
        <van-number-keyboard
          v-model="money"
          :show="show"
          theme="custom"
          :extra-key="['00', '.']"
          close-button-text="完成"
          @blur="show = false"
          @input="onInput"
          @delete="onDelete"
        />

        <!-- 按钮 -->
        <div class="btn">
            <el-button type="primary" class="submitBtn"  @click="submit">提现</el-button>
        </div>
        <!-- 验证码提示-弹窗 -->
        <el-dialog :visible.sync="dialogTableVisible" center width="80vw">
            <div class="codebox">
                <p class="title">请输入验证码</p>
                <div class="tips">请输入手机尾号 <span>{{ this.baseInfo.mobile|encryption }}</span> 收到的验证码</div>
                 <div class="sms-code">
                    <input type="text" style="width: 70%" v-model="smsCode" placeholder="请输入验证码">
                    <!-- <div class="get-sms-code">获取验证码</div> -->
                </div>
                <el-button type="primary" class="determine" @click="sendWithdrawConfirm">确定</el-button>
            </div>
        </el-dialog>
        <!-- 更改银行卡-抽屉 -->
        <el-drawer
          title="选择提现银行卡"
          :visible.sync="drawer"
          direction="btt"
          :withHeader="false"
          size="50%"
          :before-close="handleClose">
          <div class="bank-drawer">
            <div class="title">
              选择提现银行卡
              <i class="close el-icon-close"  @click="closeBank"></i>
            </div>
            <div class="bank-list">
              <div class="bank-item" v-for="item in 10" :key="item">
                <div class="bank-icon"></div>
                <div class="bank-mgs">
                  <div class="bank-content">
                    <div class="name">
                      招商银行储蓄卡（**** 45778）
                    </div>
                    <div class="check">
                      <img src="../../assets/images/hongbao/Frame@2x.png" alt="">
                    </div>
                  </div>
                  <p class="tips">预计2小时内到账</p>
                </div>
              </div>
            </div>
          </div>
        </el-drawer>
    </div>
  </div>
</template>

<script>
import {
  merchantWithdrawCard,
  withdrawApply,
  withdrawConfirm
} from '@/api/hongbao'
import {
  mapGetters
} from 'vuex'

export default {
  computed: {
    ...mapGetters(['USER_INFO'])
  },
  filters: {
    encryption (value) {
      return value ? value.slice(-4) : ''
    }
  },
  data () {
    return {
      show: true,
      money: '',
      smsCode: '',
      dialogTableVisible: false,
      drawer: false,
      baseInfo: {},
      withdrawalInfo: {}, // 提现信息
      maxAmount: 0, // 最大提现金额
      userBankInfo: {} // 用户银行卡信息
    }
  },
  created () {
    this.baseInfo = JSON.parse(window.sessionStorage.getItem('Base_Info'))
    this.maxAmount = this.$route.query.amount
    this.getUserWithdrawCard()
  },
  mounted () {
    this.$refs.moneyRef.focus()
  },
  methods: {
    changeMoney () {
      this.show = true
    },
    onInput (value) {
      console.log('money:', this.money)
    },
    onDelete (value) {
      console.log('money:', this.money)
    },

    // 回到上一页
    goBack () {
      window.history.back()
    },
    // 用户绑卡信息
    getUserWithdrawCard () {
      merchantWithdrawCard({
        org_id: this.baseInfo.org_id
      }).then(data => {
        this.userBankInfo = data
      })
    },
    // 提现申请
    submit () {
      // 提现金额不能超过可提现余额
      if (Number(this.money) > this.maxAmount / 100) {
        return this.$toast('可提现金额不足')
      }
      if (this.money && !Number(this.money)) {
        this.money = ''
        return this.$toast('请输入正确的金额')
      }
      if (!Number(this.money)) {
        return this.$toast('请输入提现金额')
      }
      const loading = this.$loading({
        lock: true,
        text: '请求中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      withdrawApply({
        org_id: this.baseInfo.org_id,
        user_code: this.baseInfo.user_code,
        amount: String(Number(this.money))
      }).then(res => {
        this.withdrawalInfo = res.data.data
        this.dialogTableVisible = true
        loading.close()
      }).catch(err => {
        console.error(err)
        this.$toast(err.msg || '请求失败')
        loading.close()
      })
    },
    // 提现确认
    sendWithdrawConfirm () {
      withdrawConfirm({
        merchant_id: this.withdrawalInfo.merchant_id, //  提现商户号
        order_no: this.withdrawalInfo.OrderNo, // 提现申请返回订单号
        total_amount: String(this.money), // 提现金额；单位，元
        plat_sms: this.smsCode, // 平台验证码
        user_code: this.baseInfo.user_code // 用户编码
      }).then(res => {
        // 通过跳往结果
        this.$router.push({
          name: 'WithdrawalResult',
          query: {
            amount: String(this.money), // 金额，元
            BankCardNo: this.userBankInfo.BankCardNo // 银行卡号
          }
        })
      }).catch(err => {
        console.error(err)
        this.$toast(err.msg)
      })
    },
    handleClose (close) {
      close()
    },
    changeBank () {
      this.drawer = true
    },
    closeBank () {
      this.drawer = false
    }
  }
}
</script>

<style>
.van-key {
  background-color: #f3f4f6;
}
.van-key--blue {
  background-color: #2386ed;
}
</style>
<style lang="scss" scoped>
.van-number-keyboard {
  background-color: #fff;
}
.inline-block {
    display: inline-block;
}
.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #f3f4f6;
  font-size: 15px;
  color: #202122;
  div {
      box-sizing: border-box;
  }
  .header {
    background-color: #fff;
    font-size: 16px;
    color: #202122;
    height: 50px;
    line-height: 50px;
    text-align: center;
    position: fixed;
    width: 100vw;
    font-weight: 600;
    border-bottom: 1px solid #dddddd;
    .back {
        font-weight: 600;
        font-size: 20px;
        position: absolute;
        left: 16px;
        top:50%;
        transform: translate(0,-50%);
    }
  }
  .main {
      padding-top: 50px;
  }
  .withdrawal-bank {
      height: 28vw;
      padding: 17px 16px 16px ;
      margin-top: 12px;
      .bank {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px 0 4px 0;
          .bankMsg {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 18px;
              height: 18px;
              margin-right: 10px;
            }
          }
          .biaozhi {
            width: 4.8vw;
            height: 4.8vw;
            background: #c4c4c4;
            border-radius: 50%;
            margin-right: 10px;
          }
      }
      .tips {
        font-size: 12px;
        color: #808388;
        // padding: 0 28px;
      }
  }
  .withdrawal-money {
      background-color: white;
      margin: 0 8px;
      border-radius: 4px;
      padding: 0 16px;
      .title{
          font-size: 16px;
          color: #505255;
          line-height: 40px;

      }
      .input {
        border-bottom: 0.5px solid #ddd;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 30px;
        color: #000000;
        margin-top: 10px;
      }
      .tips{
        font-size: 14px;
        color: #808388;
        height: 46px;
        line-height: 46px;

      }
  }
  .btn {
      width: 100%;
      padding: 4px 6px;
      position: absolute;
      bottom: 0px;
  }
  .submitBtn {
      width: 100%;
      background: #00a0ac;
      border: none;
  }

  .bank-drawer {
    .title {
      height: 18.1vw;
      background-color: #f5f5f5;
      line-height: 18.1vw;
      text-align: center;
      font-size: 16px;
      color: #202122;
      border-radius: 20px 20px 0px 0px;
      font-weight: 500;
      width: 100%;
      position: fixed;

      .close {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    .bank-list {
      padding: 0 24px;
      padding-top: 18.2vw;;
      .bank-item {
        display: flex;
        justify-content: start;
        align-items: center;
        .bank-icon{
          display: inline-block;
          width: 20px;
          height: 20px;
          background: #c4c4c4;
          border-radius: 10px;
        }
      }
      .bank-mgs {
        height: 64px;
        border-bottom:  .5px solid #ddd;
        width: 100%;
        margin-left: 12px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
      }
      .bank-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;
        .name{}
        .check{
          width: 14px;
          height: 14px;
          line-height: 14px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .tips {
        // padding: 6px 0 14px 0;
        color: #808388;
        font-size: 12px;
      }
    }
  }
}
::v-deep .el-drawer, .el-drawer__body {
  border-radius: 20px 20px 0px 0px;
}
::v-deep .el-dialog {
    border-radius: 10px!important;
    .title {
        font-size: 18px;
        color: #202122;
    }
}
::v-deep .el-dialog__body {
    padding: 0;
    padding: 0 24px;
}
.codebox{
    .title{
        font-size: 18px;
        font-weight: 500;
        color: #202122;
        text-align: center;
        padding: 16px 0;
    }
    .tips {
        text-align: center;
        padding: 0 0 16px 0;
        span {
            color:#FF7816;
            font-size: 14px;
        }
    }
    input {
        border: none;
        width: 100%;
        background: #f3f4f6;
        height: 100%;
    }

      .sms-code {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        width: 252px;
        height: 50px;
        background: #f3f4f6;
        border: 0.5px solid #cbcbcb;
        border-radius: 4px;
        padding: 0 6px;
        .get-sms-code {
          color: #00a0ac;
          width: 83px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 4px;
        }
      }
}
.determine {
      background: #00a0ac;
      width: 100%;
      margin: 24px 0;

}
::v-deep .withdrawal-money .el-input__inner {
    font-size: 30px;
    color: #000000;
    line-height: 64px;
    height: 64px;
    border:none;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
