<template>
  <div>
    <van-popup v-model="showPopup" position="bottom" round>
      <div class="title">
        <div class="user-msg">
          <span>{{ business_Info.name }}</span>
          <span class="phone">{{ $route.query.phone }}</span>
        </div>
        <!-- 买家 -->
        <div v-if="type == '1'" class="tip-txt">
          <template v-if="freightAmount">
            以下<span> {{ needPayList.length }} </span>笔物流单需要买家支付运费，合计<span>￥{{freightAmount}}</span>
          </template>
          <template v-else>
            {{ business_Info.address }}
          </template>
        </div>
        <!-- 卖家 -->
        <div v-if="type == '0'" class="tip-txt">
            {{ business_Info.address }}
        </div>
      </div>
      <div class="popup">
        <div class="list">
          <van-checkbox-group v-model="checkbox" checked-color="#00a0ac">
            <order-item
              v-for="item in list"
              :key="item.express_id"
              :item="item"
              :showCheck="true"
            >
            </order-item>
          </van-checkbox-group>
        </div>
      </div>

      <div class="footer">
        <div class="footer-left">
          <van-checkbox
            checked-color="#00a0ac"
            :icon-size="16"
            v-model="isAllSelect"
          ></van-checkbox>
          <span style="padding-left: 3px">全选</span>
        </div>
        <div class="footer-button" @click="openFreightConfirm" v-if="type === '0'">
          确认揽货({{ checkbox.length }})
        </div>
        <div class="footer-button" v-if="type === '1'" @click="openExpressConfirm">
          确认送达({{ checkbox.length }})
        </div>
        <div class="footer-button" v-if="type === '2'" @click="openCollection">
          确认收款({{ checkbox.length }})
        </div>
      </div>
    </van-popup>

    <!-- 揽货 -->
    <freight-dialog
      v-model="showFreightConfirmDialog"
      :list="freightList"
      @success="reset"
    ></freight-dialog>

    <!-- 送达 -->
    <express-dialog
      v-model="showExpressConfirmDialog"
      :list="expressList"
      @success="reset"
    ></express-dialog>

    <collection-dialog
      v-model="showCollectionConfirmDialog"
      :list="collectionList"
      @success="reset"
    ></collection-dialog>
  </div>
</template>

<script>
import orderItem from './order-item.vue'
import {
  getDriverExpressPopupList
} from '@/api/public'
import expressDialog from './express-dialog.vue'
import freightDialog from './freight-dialog.vue'
import collectionDialog from './collection-dialog.vue'

export default {
  components: {
    orderItem,
    expressDialog,
    freightDialog,
    collectionDialog
  },
  data () {
    return {
      checkbox: [],
      list: [],
      showFreightConfirmDialog: false,
      showExpressConfirmDialog: false,
      showCollectionConfirmDialog: false,
      type: '0', // 0: 揽货码 1:收货码
      expressList: [],
      freightList: [],
      collectionList: [],
      needPayList: [], // 需要支付运费的单
      freightAmount: 0 // 合计运费金额
    }
  },
  props: {
    value: Boolean
  },
  created () {
    this.type = this.$route.query.type
    if (this.$route.query.phone && this.type) {
      this.onLoad()
    }
  },
  methods: {
    reset () {
      this.checkbox.splice(0)
      this.onLoad()
    },
    onLoad () {
      this.loading = true
      getDriverExpressPopupList({
        driver_phone: localStorage.getItem('driver_mobile'),
        buyer_phone: this.$route.query.phone || '',
        search_type: this.type || '',
        org_id: ''
      })
        .then((res) => {
          this.list = this.list = res
          if (this.list.length) {
            this.needPayList = this.list.map(item => item.express_status === '4')
            this.freightAmount = this.needPayList.reduce((pre, next) => pre.re_amount + next.re_amount) || 0
            this.showPopup = true
          } else {
            if (this.type === '1') {
              this.type = '2'
              this.onLoad()
            } else {
              this.showPopup = false
            }
          }
        })
        .catch((err) => {
          this.$toast(err.msg || '请求失败')
        })
    },
    openFreightConfirm () {
      if (!this.checkbox.length) {
        return this.$toast('请选择物流单')
      }
      const list = this.list.filter(el => this.checkbox.indexOf(el.express_id) !== -1)
      this.freightList = this.checkbox.map(el => {
        const item = list.find(val => el === val.express_id)
        return {
          express_id: item.express_id,
          user_code: item.express_user_code,
          org_id: item.express_org_id
        }
      })
      this.showFreightConfirmDialog = true
    },
    openExpressConfirm () {
      if (!this.checkbox.length) {
        return this.$toast('请选择物流单')
      }
      this.expressList = this.list.filter(el => this.checkbox.indexOf(el.express_id) !== -1)
      this.showExpressConfirmDialog = true
    },
    openCollection () {
      if (!this.checkbox.length) {
        return this.$toast('请选择物流单')
      }
      this.collectionList = this.list.filter(el => this.checkbox.indexOf(el.express_id) !== -1)
      this.showCollectionConfirmDialog = true
    }
  },
  computed: {
    isAllSelect: {
      get () {
        return this.list.length ? this.list.every((el) => this.checkbox.indexOf(el.express_id) !== -1) : false
      },
      set (val) {
        if (val) {
          this.checkbox = this.list.map((el) => el.express_id)
        } else {
          this.checkbox = []
        }
      }
    },
    showPopup: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    business_Info () {
      return this.list.length ? {
        name: this.list[0].name,
        address: this.list[0].address
      } : {}
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  max-height: 60vh;
  padding-bottom: 50px;
}

.title {
  position: sticky;
  top: 0;
  background-color: #fff;
  padding: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #dddddd;
  z-index: 99999;
  div {
    line-height: 26px;
  }
  .user-msg {
    font-weight: bold;
    font-size: 18px;
    .phone {
      margin-left: 18px;
    }
  }
  .tip-txt {
    font-size: 14px;
    color:#505255;
    span {
      color: #FF7816;
    }
  }
}

.popup-header {
  padding: 16px;
  font-size: 14px;
  color: #505255;
  border-bottom: #dddddd solid 1px;

  .popup-header-title {
    font-weight: bold;
    font-size: 18px;
  }
}

.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: #dddddd solid 1px;
  padding: 0 16px;

  .footer-left {
    color: #505255;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .footer-button {
    width: 90px;
    height: 38px;
    background: #00a0ac;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
}

.dialog {
  padding: 24px 0 22px;

  .dialog-content {
    padding: 0 60px 20px;
    text-align: center;
    color: #202122;
  }
  .dialog-footer {
    display: flex;
    justify-content: center;

    .dialog-footer-button {
      height: 36px;
      width: 117px;
      border-radius: 4px;
      background: #00a0ac;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .button-cancel {
      margin-right: 20px;
      background: #f3f4f6;
      color: #00a0ac;
    }
  }
}
</style>
