<template>
    <div>
        <div class="navbar">
            <span class="navbar_title">宝秤司机端</span>
            <span></span>
        </div>
        <div class="content">
            <div class="title">
                <h1>宝秤司机端</h1>
                <p>登录即可查看揽货信息</p>
            </div>
            <div class="login-form">
                <div class="input-item">
                    <div class="label">手机号</div>
                    <div class="input-content">
                        <input
                            maxlength="11"
                            type="text"
                            class="input"
                            v-model="mobile"
                            placeholder="请输入手机号"
                        />
                    </div>
                </div>
                <div class="input-item">
                    <div class="label">验证码</div>
                    <div class="input-content">
                        <input
                            maxlength="6"
                            type="text"
                            class="input"
                            v-model="sms_code"
                            placeholder="请输入验证码"
                        />
                        <span
                            :style="{ color: !interval ? '#00a0ac' : '#A3A3A3' }"
                            @click="sendMessage"
                        >{{interval ?  `${timer}秒后可获取` : '获取验证码'}}</span>
                    </div>
                </div>
            </div>
            <div class="login-btn" @click="login">确认登录</div>
        </div>
    </div>
</template>

<script>
import { getSmsCode } from '@/api/hongbao'
import { driverLogin } from '@/api/user'

export default {
  data () {
    return {
      mobile: '',
      sms_code: '',
      interval: null,
      timer: 0
    }
  },
  created () { },
  methods: {
    // 发送验证码
    sendMessage () {
      if (this.interval) {
        return
      }
      getSmsCode({
        phone_number: this.mobile,
        template_id: '8'
      }).then(() => {
        this.timer = 60
        this.interval = setInterval(() => {
          this.timer -= 1
          if (this.timer <= 0) {
            clearInterval(this.interval)
            this.interval = null
          }
        }, 1000)
      }).catch(err => {
        this.$toast(err.msg || '获取验证码失败')
      })
    },
    login () {
      if (!/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(this.mobile)) {
        return this.$toast('请输入正确的手机号码')
      }

      if (this.sms_code.length < 4) {
        return this.$toast('请输入正确的验证码')
      }
      driverLogin({
        sms_code: this.sms_code,
        mobile: this.mobile
      }).then(() => {
        localStorage.setItem('driver_mobile', this.mobile)
        this.$router.replace({
          name: 'driver',
          query: this.$route.query
        })
      }).catch(err => {
        console.error(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
    border-bottom: 1px solid rgb(235, 231, 231);
}
.content {
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 13vw;
}
.title {
    h1 {
        font-size: 6.93vw;
    }
    p {
        color: #505255;
        font-size: 4vw;
    }
}
.login-form {
    margin: 4vw 0;
    padding: 10px 0;
    .label {
        color: #505255;
        font-size: 3.47vw;
    }
    .input-item {
        border-bottom: 1px #ddd solid;
        margin-bottom: 10px;
    }
    .input {
        border: none;
        outline: none;
        height: 50px;
        color: #202122;
        font-size: 16px;
    }
    .input-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #00a0ac;
    }
}
.login-btn {
    font-size: 16px;
    width: 83vw;
    height: 12vw;
    line-height: 12vw;
    background: #00a0ac;
    border-radius: 4px;
    text-align: center;
    color: #fff;
}
</style>
