<template>
  <div>
    <div class="navbar">
      <img src="@/assets/images/back_black.png" class="navbar_back" @click="toBack" />
      <span class="navbar_title">申请成为总代理</span>
      <span></span>
    </div>

    <div class="content">
      <img src="@/assets/images/page_5_1.png" alt />
      <div class="form">
        <el-form :model="formData" :rules="rules" ref="formRef">
          <el-form-item label="请填写您的真实姓名" prop="name">
            <el-input v-model="formData.name" placeholder="请输入您的姓名" clearable></el-input>
          </el-form-item>
          <el-form-item label="请填写您的手机号" prop="mobile">
            <el-input v-model="formData.mobile" placeholder="请输入您的手机号码" maxlength="11" clearable></el-input>
          </el-form-item>
          <el-form-item label="是否有生产/售卖/购买农产品的客户资源" prop="customer_resources">
            <el-radio-group v-model="formData.customer_resources" style="width: 100%">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="请填写你看到该信息渠道的来源" prop="channel">
            <el-input v-model="formData.channel" placeholder="请输入您的渠道来源" clearable></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="btn">
        <el-button type="primary" class="submitBtn" @click="openSmsCode">提交申请</el-button>
      </div>

      <el-dialog :visible.sync="dialogTableVisible" :show-close="false" title="验证手机号码" center top="30vh" width="80vw">
        <div class="codebox">
          <div class="tips">
            请输入尾号<span class="default">{{ formData.mobile.slice(7, 11) }}</span>的验证码：
          </div>
          <div class="flex-between sms">
              <input type="text" v-model="formData.sms_code" ref="inputCodeRef" />
              <span
                class="default"
                :class="{ 'get-sms-code': !interval, 'disable-code': interval }"
                @click="getCode"
              >{{ codeBtnStr }}</span>
          </div>
          <div class="determine flex-center" @click="submit">确定</div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getSmsCode, AddGeneralAgentApply } from '@/api/agent'

export default {
  data () {
    return {
      isUniApp: false,
      envLoading: true,
      dialogTableVisible: false,
      interval: 0,
      codeBtnStr: '获取验证码',
      formData: {
        name: '', // 姓名
        mobile: '', // 手机号
        channel: '', // 渠道来源
        apply_type: '0', // 申请类型:0-宝秤总代理, 待添加
        sms_code: '', // 短信验证码
        customer_resources: '1' // 0:没有 1:有
      },
      rules: {
        name: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
        mobile: [
          { required: true, message: '请输入您的手机号码', trigger: 'blur' },
          {
            pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur'
          }
        ],
        customer_resources: [
          { required: true, message: '请选择是否有客户资源', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    document.title = '总代理申请'
    this.isUniApp = !!this.$route.query.auth_token
    document.addEventListener('UniAppJSBridgeReady', () => {
      this.envLoading = false
    })
  },
  methods: {
    // 返回
    toBack () {
      if (this.isUniApp) {
        if (this.envLoading) {
          this.$toast('环境初始化中，请稍后')
        } else {
          window.uni.navigateBack({
            delta: 1
          })
        }
      } else {
        window.history.back()
      }
    },
    openSmsCode () {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.formData.sms_code = ''
          this.dialogTableVisible = true
        }
      })
    },
    // 获取验证码
    getCode () {
      if (this.interval) {
        return
      }
      getSmsCode({
        phone_number: this.formData.mobile,
        template_id: '8'
      })
        .then((res) => {
          this.interval = 60
          this.timer()
        })
        .catch((err) => {
          console.error(err)
          this.$toast(err.msg || '请求失败')
        })
    },
    timer () {
      this.interval--
      if (this.interval > 0) {
        this.codeBtnStr = '重新获取(' + this.interval + 's)'
        setTimeout(() => {
          this.timer()
        }, 1000)
      } else {
        this.codeBtnStr = '获取验证码'
      }
    },
    submit () {
      if (!this.formData.sms_code) {
        this.$refs.inputCodeRef.focus()
        return this.$toast('请填写验证码')
      }

      AddGeneralAgentApply(this.formData)
        .then((res) => {
          // 调转
          this.$router.push({
            name: 'AgentUnderReview'
          })
        })
        .catch((err) => {
          console.error(err)
          this.$toast(err.msg || '请求失败')
        })
    }
  }
}
</script>

<style lang="scss" scoped>

.navbar {
  z-index: 1;
}
::v-deep .van-toast {
  z-index: 999999;
}
.el-form-item {
  margin-bottom: 10px;
}
.default {
  color: #00a0ac;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.full-w {
  width: 100%;
}
div {
  box-sizing: border-box;
}
html::-webkit-scrollbar,
body::-webkit-scrollbar,
.content::-webkit-scrollbar {
  display: none;
  // width: 0 !important;
  width: 100px !important;
  height: 0 !important;
  -webkit-appearance: none;
  background: transparent;
}
img {
  max-width: 100%;
}
.form {
  padding: 20px 16px;
  padding-bottom: 100px;
  // border-radius: 10px;
  // background-color: white;
  // position: relative;
  // top: -10px;
}
.form-label {
  font-weight: 500;
  padding: 10px;
  padding-bottom: 5px;
}
.content {
  height: calc(100vh - 13.3333vw);
  overflow-y: scroll;
  background-color: #f3f3f3;

  .btn {
    width: 100%;
    padding: 5px 15px;
    position: fixed;
    bottom: 0px;
    background-color: #fff;
    .submitBtn {
      width: 100%;
      background: #00a0ac;
      border: none;
      border-radius: 4px;
    }
  }
}
::v-deep .el-dialog {
  border-radius: 10px !important;
  .el-dialog__header {
    padding-top: 30px;
  }
  .el-dialog__body {
    padding: 10px;
    font-size: 16px;
    color: black;
  }
}

.codebox {
  padding: 0 13px;
  padding-bottom: 10px;

  .sms {
    line-height: 40px;
    margin: 10px 0px 20px 0px;
    padding: 0 4px;
    border-bottom: 1px solid #ccc;
    input {
      border: none;
      max-width: 70%;
    }
    span {
      white-space: nowrap;
      font-size: 13px;
      width: 73px;
      height: 20px;
      line-height: 20px;

    }
    .get-sms-code {
      color: #00a0ac;
      text-align: center;
      border: .5px solid #00a0ac;
      border-radius: 5px;
    }
    .disable-code {
      color: #a3a3a3;
      text-align: center;
      pointer-events: none;
    }
  }
  .determine {
    width: 100%;
    color: white;
    background-color: #00a0ac;
    font-size: 16px;
    height: 40px;
    // width: 254px;
    border-radius: 4px;
  }
}

.tips {
  text-align: left;
}
</style>
