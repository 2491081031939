<!--佣金明细-->
<template>
  <div>
    <!-- 遮罩 -->
    <div class="mask"
         @touchmove.prevent
         v-if="(isNewUser && isWx()) || rulesVisible || withdrawalVisible">
      <!-- 引导：新用户 && 只弹一次 -->
      <div class="tool-tips"
           v-if="isNewUser  && isWx()">
        <p>点击收藏该页面，以免错失可提取现金</p>
        <span class="know"
              @click="isNewUser = false">知道了</span>
      </div>
    </div>
    <!-- 内容 -->
    <div class="container"
         :class="{ needMask: isNewUser || rulesVisible || withdrawalVisible }">
      <!-- 金额 -->
      <div class="withdrawal">
        <p class="rule"
           @click="rulesVisible = true">规则</p>
        <div class="reward">
          <p class="title">我的奖励</p>
          <div class="flex-between">
            <el-row>
              <el-col :span="24"
                      class="money">
                <span>￥</span>
                {{ totalMoney || "0" }}
              </el-col>
              <el-col :span="24">
                <div class="desc">累计佣金总额（元）</div>
              </el-col>
            </el-row>
            <div class="withdrawal-btn"
                 @click="withdrawalVisible = true">
              提现
            </div>
          </div>
        </div>
      </div>
      <!-- 列表 -->

      <div class="record">
        <p class="title">推广奖励记录</p>
        <van-list class="record-list"
                  :error.sync="error"
                  v-model="loading"
                  :finished="finished"
                  offset="50"
                  :finished-text="finishedText"
                  @load="onLoad">
          <div class="record-item"
               v-for="(item, key) in list"
               :key="key">
            <el-row>
              <el-col :span="18"
                      class="record-name">文章商品推广奖励</el-col>
              <el-col :span="6"
                      class="money">+{{ item.scale_amount }}</el-col>
            </el-row>
            <div class="detail">
              <!-- <el-row>购买手机号：{{ formatPhone(item.pay_org_name) }}</el-row> -->
              <el-row>订单编号：{{ item.order_code }}</el-row>
              <el-row class="p-t-8">下单时间：{{ item.create_time }}</el-row>
            </div>
          </div>
        </van-list>
      </div>
      <!-- 规则弹窗 -->
      <div class="rules-dialog"
           v-if="rulesVisible">
        <div class="title">奖励规则说明</div>
        <p>1、奖励金额是用户从你的专属链接或专属海报中购买了商品再发放</p>
        <p>2、提现需要到宝秤新集贸APP中进行</p>
        <p>3、最终解释权归’宝秤新集贸’所有</p>
        <div class="close-btn"
             @click="closeRules()">
          <img src="@/assets/images/page_4_3.png"
               alt />
        </div>
      </div>
      <!-- 提现提示弹窗 -->
      <div class="withdrawal-dialog"
           v-if="withdrawalVisible">
        <div class="title">提现提示</div>
        <p>
          请前往APP的
          <span>我的-资金账户</span>进行提现
        </p>
        <div class="flex-between footer-btn">
          <span class="cancle"
                @click="withdrawalVisible = false">取消</span>
          <span class="download"
                @click="goDownload()">下载APP</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWalletDetail, getTradLog } from '@/api/amount'

export default {
  data () {
    return {
      totalMoney: 0, // 商户电子钱包余额信息
      list: [], // 推广记录列表
      first_org_id: '', // 记录访问的org_id
      rulesVisible: false, // 规则弹窗
      withdrawalVisible: false, // 提现提示弹窗
      isNewUser: false, // 是否新用户

      page: 0,
      error: false, // 加载更多失败
      loading: false,
      finished: false,
      org_id: '',
      finishedText: '没有更多数据'
    }
  },
  computed: {
    // 是否第一次打开
    isFirst () {
      return this.first_org_id !== this.$route.query.org_id
    }
  },
  created () {
    document.title = '佣金明细'
    this.org_id = this.$route.query.org_id || ''
    this.first_org_id = window.localStorage.getItem('firstVisit')
    //  没有手机号=》新用户
    if (!this.$route.query.mobile) {
      if (this.isFirst) {
        this.rulesVisible = true
        window.localStorage.setItem(
          'firstVisit',
          this.$route.query.org_id || 'true'
        )
      }
    }
    if (this.$route.query.org_id) {
      this.getDetail()
    }
  },
  methods: {
    // 是否微信
    isWx () {
      var ua = navigator.userAgent.toLowerCase()// 获取判断用的对象
      return ua.match(/MicroMessenger/i) == 'micromessenger'
    },
    // 格式化手机号
    formatPhone (phone) {
      return `${phone.slice(0, 3)}****${phone.slice(-4)}`
    },
    // 关闭规则弹窗
    closeRules () {
      this.rulesVisible = false
      // （如果是新用户第一次进入，关闭后需要弹出引导浮层）
      if (this.isFirst && this.isWx()) {
        this.first_org_id = window.localStorage.getItem('firstVisit')
        this.isNewUser = !this.$route.query.mobile
      }
    },
    // 获取可提现金额
    getDetail () {
      getWalletDetail({
        org_id: this.$route.query.org_id || ''
      })
        .then((res) => {
          this.totalMoney = res.data.data
          console.log(res.data.data)
        })
        .catch((err) => {
          console.error(err)
          this.$toast(err.msg || '请求失败')
        })
    },
    // 获取推广记录
    getList () {
      getTradLog({
        org_id: this.$route.query.org_id || '',
        page: this.page,
        per_page: 20
      })
        .then((res) => {
          const data = res.data.data
          setTimeout(() => {
            this.list = [...this.list, ...data]
            this.loading = false
            if (data.length < 20) {
              this.finished = true
            }
            if (this.page === 1 && data.length === 0) {
              this.finishedText = '暂无数据'
            } else {
              this.finishedText = '没有更多数据'
            }
          }, 500)
        })
        .catch(() => {
          this.error = true
          this.loading = false
          this.finished = true
          this.finishedText = '请求失败'
        })
    },

    // 加载更多
    onLoad () {
      if (!this.$route.query.org_id) {
        this.finishedText = '没有更多数据'
        this.loading = false
        this.finished = true
        return false
      }
      if (this.error) {
        this.getList()
      } else {
        this.page += 1
        this.getList()
      }
    },
    // 点击-下载App
    goDownload () {
      this.$router.push({
        name: 'Download'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  box-sizing: border-box;
}
img {
  max-width: 100%;
}
.p-t-8 {
  padding-top: 8px;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mask {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 8;
}
.needMask {
  height: 100vh !important;
  overflow: hidden !important;
}
.container {
  background-color: #f3f4f6;
}
// 浮层提示
.tool-tips {
  z-index: 9;
  width: 60vw;
  padding: 10px;
  background-color: #ffffff;
  position: absolute;
  top: 38px;
  right: 20px;
  border-radius: 6px;
  font-size: 14px;
  .know {
    background-color: #00a0ac;
    color: white;
    float: right;
    font-size: 12px;
    border: 1px solid white;
    padding: 4px 7px;
    border-radius: 10px;
  }
}
.tool-tips::before {
  z-index: 9;
  content: "";
  position: absolute;
  top: -40px;
  right: 20px;
  width: 0;
  height: 0;
  opacity: 1;
  border-left: 20px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 40px solid #ffffff;
}

.withdrawal {
  z-index: 1;
  position: sticky;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: #fff;
  font-size: 14px;
  padding: 3vw 4.8vw;
  color: white;
  background: url(../../assets/images/page_4_1.png) no-repeat center;
  background-size: cover;
  .rule {
    text-align: right;
  }
  .reward {
    padding-bottom: 16px;
    .title {
      font-size: 16px;
    }
    .money {
      font-size: 36px;
      span {
        font-size: 19px;
      }
    }
    .desc {
      padding-left: 32px;
    }
  }
  &-btn {
    font-size: 16px;
    text-align: center;
    width: 22.7vw;
    padding: 2px 0;
    border-radius: 13px;
    background-color: #fb9f2f;
    font-weight: 700;
  }
}

.record {
  margin-top: 10px;
  background-color: #fff;
  .title {
    font-size: 16px;
    font-weight: 700;
    padding: 16px;
  }
  &-list {
  }
  &-item {
    padding: 16px;
    border-top: 0.5px solid #ededed;
    .record-name {
      color: #202122;
      font-size: 15px;
    }
    .money {
      color: #fd3d39;
      font-size: 15px;
      text-align: right;
    }
    .detail {
      font-size: 12px;
      color: #808388;
      padding-top: 16px;
    }
  }
}

.rules-dialog {
  z-index: 9;
  background-color: #fff;
  border-radius: 8px;
  width: 80vw;
  padding: 30px 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .title {
    font-size: 16px;
    text-align: center;
    margin-bottom: 22px;
  }
  p {
    font-size: 13px;
    color: #555555;
    line-height: 20px;
    text-align: left;
  }
  .close-btn {
    width: 8.53vw;
    height: 8.53vw;
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.withdrawal-dialog {
  z-index: 9;
  background-color: #fff;
  text-align: center;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  // height: 157px;
  border-radius: 8px;
  padding: 20px 23px;
  font-size: 14px;
  .title {
    font-size: 18px;
    color: #202122;
  }
  p {
    color: #505255;
    padding: 17px 0 27px 0;
    span {
      color: #00a0ac;
    }
  }
  .footer-btn {
    .cancle {
      width: 122px;
      height: 40px;
      line-height: 40px;
      background: #f3f4f6;
      color: #505255;
      border-radius: 8px;
      margin-right: 10px;
    }
    .download {
      width: 122px;
      height: 40px;
      line-height: 40px;
      background: #00a0ac;
      color: white;
      border-radius: 8px;
    }
  }
}
</style>
