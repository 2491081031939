import { app } from "@/plugins/appAxios";
import { mangeApi } from "@/plugins/mangeAxios";
import { api } from "@/plugins/axios";
export function driverLogin(params) {
  return app("/driver_login", params);
}

export function smsLogin(params) {
  return app("/sms_code/login", params);
}

export function loginRole(params) {
  return mangeApi("/login_role", params);
}

export function bindWechat(params) {
  return app("/general/bind/wechat/", params);
}
