import { app } from '@/plugins/appAxios'
import { api } from '@/plugins/axios'

// 发送短信验证码
export function getSmsCode (params) {
  if (!/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(params.phone_number)) {
    return Promise.reject({
      msg: '请输入正确的手机号码',
      code: '9000'
    })
  }
  return app('/send/sms/code/', params)
}

// 总代申请
export function AddGeneralAgentApply (params) {
  return api('/generate/general_agent_apply/add', params)
}
