<template>
  <van-dialog v-model="show" title="确认揽货" :width="300" :show-confirm-button="false">
    <div class="dialog">
      <p class="dialog-content">是否揽收选中的物流单？</p>
      <div class="dialog-footer">
        <div class="dialog-footer-button button-cancel" @click="show = false">取消</div>
        <div class="dialog-footer-button" @click="confirm">确认揽收</div>
      </div>
    </div>
  </van-dialog>
</template>

<script>
import { confirmExpress } from '@/api/public'

export default {
  data () {
    return {}
  },
  props: {
    value: Boolean,
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    confirm () {
      confirmExpress({
        express_id_list: this.list,
        express_confirm: '1',
        order_confirm: '0'
      }).then(res => {
        this.show = false
        this.$emit('success')
      }).catch(err => {
        this.$toast(err.msg || '请求失败')
      })
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  padding: 24px 0 22px;

  .dialog-content {
    padding: 0 60px 20px;
    text-align: center;
    color: #202122;
  }
  .dialog-footer {
    display: flex;
    justify-content: center;

    .dialog-footer-button {
      height: 36px;
      width: 117px;
      border-radius: 4px;
      background: #00a0ac;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .button-cancel {
      margin-right: 20px;
      background: #f3f4f6;
      color: #00a0ac;
    }
  }
}
</style>
