<template>
  <div>
    <div class="content">
      <img src="@/assets/images/prev_white.png" alt="" class="back-icon" @click="toBack" />
      <img src="@/assets/images/page_6_2.png" alt />
      <div class="btn">
        <el-button type="primary" class="submitBtn">功能正在开发中，敬请期待</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isUniApp: false,
      envLoading: true
    }
  },
  created () {
    document.title = '一件代发'
    this.isUniApp = this.$route.query.is_uni_app === '1'
    document.addEventListener('UniAppJSBridgeReady', () => {
      this.envLoading = false
    })
  },
  methods: {
    toBack () {
      if (this.isUniApp) {
        if (this.envLoading) {
          this.$toast('环境初始化中，请稍后')
        } else {
          window.uni.navigateBack({
            delta: 1
          })
        }
      } else {
        window.history.back()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.back-icon {
  position: absolute;
  top: 3.7333vw;
  left: 3.7333vw;
  height: 5.3333vw;
  width: 5.3333vw;
}

div {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

.content {
  padding-bottom: 40px;
  padding-top: 0;
}

.btn {
  width: 100%;
  padding: 5px 8px;
  position: fixed;
  bottom: 0px;
  background-color: #fff;

  .submitBtn {
    width: 100%;
    border: none;
    border-radius: 4px;
    background: #cbcbcb;
  }
}
</style>
