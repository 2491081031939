<template>
  <div>
    <div class="navbar">
      <span class="navbar_title">宝秤司机端</span>
      <span></span>
    </div>
    <div class="tab-lis">
      <div
        class="tab-item"
        :class="{ active: active === index }"
        v-for="(tab, index) in tabs"
        :key="index"
        @click="tabChange(index)"
      >
        {{ tab.name }}
      </div>
    </div>

    <div class="search">
        <img src="@/assets/images/search.png" alt="" class="search-icon" />
        <input type="text" v-model="searchText" class="search-input">
        <span @click="reset">搜索</span>
      </div>
    <div class="content">
      <div v-for="(tab, tabIndex) in tabs" :key="tabIndex">
        <van-list
          v-show="tabIndex === active"
          v-model="tab.loading"
          :finished="tab.finished"
          finished-text="没有更多了"
          @load="onLoad(tabIndex)"
        >
          <div class="list">
            <van-checkbox-group v-model="checkbox" checked-color="#00a0ac">
              <order-item
                v-for="item in tab.list"
                :key="item.express_id"
                :item="item"
                :showCheck="active === 0 || active === 1"
                @handleChoose="handleFunc"
              >
                <template slot="footer">
                  <van-button size="mini" plain v-if="item.express_status === '2'" @click.stop="openFreightConfirm(item)">
                    确认揽货
                  </van-button>

                  <van-button size="mini" plain v-if="['3'].includes(item.express_status) && item.sign_order_confirm == '0'" @click.stop="openExpressConfirm(item)">
                    确认送达
                  </van-button>

                  <van-button size="mini" plain v-if="item.express_status === '4'" @click.stop ="openCollectionDialog(item)"
                  >收取运费</van-button>

                  <van-button size="mini" plain @click.stop ="openCode(item)" v-if="item.unpaid_amount !== '0.00' && item.sign_express_confirm === '1'">代收货款</van-button>

                </template>
              </order-item>
            </van-checkbox-group>
          </div>
        </van-list>
      </div>
    </div>

    <div class="footer" v-if="active === 0 || active === 1">
      <div class="footer-left">
        <van-checkbox
          checked-color="#00a0ac"
          :icon-size="16"
          v-model="isAllSelect"
        ></van-checkbox>
        <span style="padding-left: 3px">全选</span>
      </div>
      <div class="footer-button" v-if="active === 0" @click="openFreightConfirm()">确认揽货({{checkbox.length}})</div>
      <div class="footer-button" v-if="active === 1" @click="openExpressConfirm()">确认送达({{checkbox.length}})</div>
    </div>

    <van-dialog v-model="showCode" :width="300" :show-confirm-button="false" closeOnClickOverlay>
      <div class="qr-code">
        <img :src="qrCodeImage" alt="" class="dialog-code" />
        <p class="code-title">代收货款 <span class="code-price">￥{{unpaid_amount}}</span></p>
      </div>
    </van-dialog>
    <order-popup v-model="showPopup"></order-popup>

    <!-- 揽货 -->
    <freight-dialog v-model="showFreightConfirmDialog" :list="freightList" @success="reset"></freight-dialog>

    <!-- 送达 -->
    <express-dialog v-model="showExpressConfirmDialog" :list="expressList" @success="reset"></express-dialog>

    <collection-dialog
      v-model="showCollectionConfirmDialog"
      :list="collectionList"
      :amount="re_amount"
      @success="reset"
    ></collection-dialog>
  </div>
</template>

<script>
import { getDriverExpressList } from '@/api/public'
import orderItem from './views/order-item.vue'
import orderPopup from './views/order-popup.vue'
import expressDialog from './views/express-dialog.vue'
import freightDialog from './views/freight-dialog.vue'
import collectionDialog from './views/collection-dialog.vue'

import QRCode from 'qrcode'

export default {
  components: {
    orderItem,
    orderPopup,
    freightDialog,
    expressDialog,
    collectionDialog
  },
  data () {
    return {
      mobile: '',
      active: 0,
      tabs: [
        {
          name: '待揽货',
          value: '1',
          list: [],
          loading: false,
          finished: false,
          page: 1
        },
        {
          name: '运输中',
          value: '2',
          list: [],
          loading: false,
          finished: false,
          page: 1
        },
        {
          name: '已送达',
          value: '3',
          list: [],
          loading: false,
          finished: false,
          page: 1
        },
        {
          name: '全部',
          value: '0',
          list: [],
          loading: false,
          finished: false,
          page: 1
        }
      ],
      showCode: false,
      checkbox: [],
      showPopup: false,
      showFreightConfirmDialog: false,
      showExpressConfirmDialog: false,
      showCollectionConfirmDialog: false,
      re_amount: '',
      searchText: '',
      qrCodeImage: '',
      unpaid_amount: '', // 订单未支付金额
      freightList: [], // 揽货
      expressList: [], // 送达
      collectionList: []
    }
  },
  computed: {
    isAllSelect: {
      get () {
        const list = this.tabs[this.active].list
        return list.length ? list.every((el) => this.checkbox.indexOf(el.express_id) !== -1) : false
      },
      set (val) {
        const list = this.tabs[this.active].list
        if (val) {
          this.checkbox = list.map((el) => el.express_id)
        } else {
          this.checkbox = []
        }
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    if (!localStorage.getItem('driver_mobile')) {
      next({
        name: 'driverLogin',
        query: to.query
      })
    } else {
      next()
    }
  },
  created () {
    this.mobile = localStorage.getItem('driver_mobile')
    this.getList()
  },
  methods: {
    reset () {
      const item = this.tabs[this.active]
      item.page = 1
      item.loading = false
      item.finished = false
      item.list = []
      this.checkbox = []
      this.getList()
    },
    openExpressConfirm (currentItem) {
      // 某行单独操作
      if (currentItem) {
        this.expressList = [{ ...currentItem }]
      } else { // 勾选操作
        if (!this.checkbox.length) {
          return this.$toast('请选择物流单')
        }

        this.expressList = this.tabs[this.active].list.filter(el => this.checkbox.indexOf(el.express_id) !== -1)
      }

      this.showExpressConfirmDialog = true
    },
    openFreightConfirm (currentItem) {
      // 某行单独操作
      if (currentItem) {
        this.freightList = [{
          express_id: currentItem.express_id,
          user_code: currentItem.express_user_code,
          org_id: currentItem.express_org_id
        }]
      } else { // 勾选操作
        if (!this.checkbox.length) {
          return this.$toast('请选择物流单')
        }
        const list = this.tabs[this.active].list.filter(el => this.checkbox.indexOf(el.express_id) !== -1)
        this.freightList = this.checkbox.map(el => {
          const item = list.find(val => el === val.express_id)
          return {
            express_id: item.express_id,
            user_code: item.express_user_code,
            org_id: item.express_org_id
          }
        })
      }

      this.showFreightConfirmDialog = true
    },
    onLoad () {
      this.getList()
    },
    tabChange (index) {
      this.checkbox = []
      this.active = index
      this.reset()
    },
    openCollectionDialog (item) {
      this.re_amount = item.re_amount
      this.collectionList = [item]
      this.showCollectionConfirmDialog = true
    },
    openCode (item) {
      this.unpaid_amount = item.unpaid_amount
      QRCode.toDataURL(JSON.stringify({
        params_type: 'order_pay',
        params: {
          order_code: item.order_code,
          amount: item.unpaid_amount,
          order_seller_org_id: item.order_org_id
        }
      }), { rendererOpts: { quality: 1 } }).then(
        (result) => {
          this.qrCodeImage = result
        }
      )
      this.showCode = true
    },
    getList () {
      const item = this.tabs[this.active]
      item.loading = true
      getDriverExpressList({
        driver_phone: this.mobile, // 司机手机号
        buyer_phone: this.$route.query.type === '1' ? this.$route.query.phone : '', // 出示收货码的用户手机号, 0: 揽货码/物流码, 1:收货码
        page: item.page,
        per_page: 20,
        search_user: this.searchText,
        search_data: item.value, // 0: 全部 1:待揽货 2:运输中 3:已送达
        search_type: this.$route.query.type || '', // 0: 揽货码 1:收货码
        org_id: '' // 需要揽货/需要收货的 express_org_id
      }).then((res) => {
        item.finished = res.list.length < 20
        item.list = item.list.concat(res.list)
        item.page += 1
        item.loading = false
      })
    },
    // 跳转物流单页面
    handleFunc (item) {
      this.$router.push({
        path: '/logistics',
        query: {
          express_id: item.express_id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  border-bottom: 1px solid rgb(235, 231, 231);
  z-index: 9;
}

.tab-lis {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #505255;
  border-bottom: #dddddd solid 1px;
  background: #fff;
  position: fixed;
  top: 13.3333vw;
  left: 0;
  right: 0;
  z-index: 9;

  .tab-item {
    height: 40px;
    line-height: 40px;
    text-align: center;
    flex: 1;

    &.active {
      color: #00a0ac;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        bottom: 5px;
        left: 50%;
        margin-left: -12px;
        width: 24px;
        height: 2px;
        background: #00a0ac;
      }
    }
  }
}

.search {
    height: 40px;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // color: #808388;
    position: fixed;
    left: 0;
    right: 0;
    top: calc(13.3333vw + 40px);
    color: #202122;
    background: #f3f4f6;
    z-index: 9;

    .search-icon {
      height: 16px;
      width: 16px;
      margin-right: 3px;
    }

    .search-input {
      flex: 1;
      border: none;
      outline: none;
      height: 100%;
      background: none;
      padding-left: 10px;
      // width: 100%÷÷;
    }
  }

.content {
  min-height: 100vh;
  box-sizing: border-box;
  background: #f3f4f6;
  padding-top: calc(13.3333vw + 40px + 40px);
  padding-bottom: 50px;
}

.list {
  background: #fff;
  .item {
    border-bottom: #dddddd 1px solid;
    color: #808388;
    font-size: 12px;
    display: flex;
    align-items: center;

    .item-checkbox {
      width: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .item-content {
      padding: 10px 16px;
      padding-left: 0;
      flex: 1;
    }

    .item-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #56bd48;
    }
    .item-title {
      font-weight: bold;
      font-size: 14px;
      color: #202122;
    }

    .item-num {
      padding: 5px 0;
    }

    .item-row {
      display: flex;
      align-items: center;
    }

    .label {
      width: 66px;
    }

    .item-footer {
      text-align: right;
    }
  }
}

.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: #dddddd solid 1px;
  padding: 0 16px;

  .footer-left {
    color: #505255;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
}

.dialog {
  padding: 24px 0 22px;

  .dialog-content {
    padding: 0 60px 20px;
    text-align: center;
    color: #202122;
  }
  .dialog-footer {
    display: flex;
    justify-content: center;

    .dialog-footer-button {
      height: 36px;
      width: 117px;
      border-radius: 4px;
      background: #00a0ac;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .button-cancel {
      margin-right: 20px;
      background: #f3f4f6;
      color: #00a0ac;
    }
  }
}

.qr-code {
  text-align: center;
  padding: 20px;

  .dialog-code {
    width: 185px;
    height: 185px;
    margin-bottom: 22px;
  }

  .code-title {
    color: #505255;
    font-size: 16px;

    .code-price {
      color: #fa675c;
      margin-left: 6px;
    }
  }
}

.footer-button {
  width: 90px;
  height: 38px;
  background: #00a0ac;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
</style>
