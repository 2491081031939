<template>
  <div>
    <div class="item">
      <div class="item-checkbox" v-if="showCheck">
        <van-checkbox :name="item.express_id" :icon-size="16"></van-checkbox>
      </div>
      <div
        class="item-content"
        :style="{ 'padding-left': showCheck ? '' : '16px' }"
        @click.stop="$emit('handleChoose', item)"
      >
        <div class="item-header">
          <div class="item-title">物流单 {{ item.express_id }}</div>
          <div>{{ item.express_status_str }} - {{ item.payment_status_str }}</div>
        </div>

        <template v-if="item.express_status === '4' || item.express_status === '5'">
          <div class="item-row item-num">
            <span class="label">包裹数量</span>
            {{ item.package_qty }}
          </div>
          <div class="item-row"><span class="label">收货人</span> {{ item.name }}</div>
        </template>

        <template v-if="item.express_status === '2' || item.express_status === '3'">
          <div class="item-row item-num">
            <span class="label">包裹数量</span>
            {{ item.package_qty }}
          </div>
          <div class="item-row">
            <span class="label">总重量</span> {{ item.package_weight }}
          </div>
          <div class="item-row item-num">
            <span class="label">备注</span> {{ item.package_remark || "无" }}
          </div>
        </template>

        <div class="item-footer">
            <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  props: {
    showCheck: Boolean,
    item: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
    // border-bottom: #dddddd 1px solid;
    color: #808388;
    font-size: 12px;
    display: flex;
    align-items: center;

    .item-checkbox {
      width: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .item-content {
      padding: 10px 16px;
      padding-left: 0;
      flex: 1;
      border-bottom: #dddddd 1px solid;
    }

    .item-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #56bd48;
    }
    .item-title {
      font-weight: bold;
      font-size: 14px;
      color: #202122;
    }

    .item-num {
      padding: 5px 0;
    }

    .item-row {
      display: flex;
      align-items: center;
    }

    .label {
      width: 66px;
    }

    .item-footer {
      text-align: right;
    }
  }

</style>
