<template>
  <div class="container">
    <!-- 顶部 -->
    <div class="header">
        <i class="back el-icon-arrow-left" @click="goBack"></i>
        结果详情
        <span class="success"  @click="goHongbao">完成</span>
    </div>
    <div class="main">
      <!-- 时间轴 -->
      <div class="time-line">
        <el-timeline>
          <el-timeline-item
            v-for="(activity, index) in activities"
            :key="index"
            :icon="activity.icon"
            :type="activity.type"
            :color="activity.color"
            :size="activity.size"
            :timestamp="activity.timestamp">
            {{activity.content}}
          </el-timeline-item>
        </el-timeline>
      </div>
      <!-- 提现信息 -->
      <div class="message">
        <div class="money">
          <span>提现金额</span>
          <span>￥ {{ withdrawalInfo.amount }}</span>
        </div>
        <div class="bank">
          <span>到账银行卡</span>
          <span>尾号 {{ withdrawalInfo.BankCardNo|encryption }} 储蓄卡</span>
        </div>
        <div class="time">
          <span>提现时间</span>
          <span>{{ nowTime }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  filters: {
    encryption (value) {
      return value ? value.slice(-4) : ''
    }
  },
  data () {
    return {
      withdrawalInfo: {
        amount: 0,
        BankCardNo: ''
      },
      nowTime: '',
      activities: [{
        content: '提现￥23345.45 申请已提交，银行处理中',
        size: 'large',
        type: 'primary',
        icon: 'el-icon-time',
        color: '#56BD48'
      }, {
        content: '已到账',
        size: 'large',
        color: '#56BD48',
        icon: 'el-icon-success'
      }]
    }
  },
  created () {
    this.withdrawalInfo = this.$route.query
    this.activities[0].content = `提现￥${this.withdrawalInfo.amount}申请已提交，银行处理中`
    this.formatDate()
  },
  methods: {
    // 获取当前时间
    formatDate () {
      const now = new Date()
      const year = now.getFullYear()
      const month = String(now.getMonth() + 1).padStart(2, '0') // 获取当前月份(0-11,0代表1月)
      const day = String(now.getDate()).padStart(2, '0') // 获取当前日(1-31)
      const hours = String(now.getHours()).padStart(2, '0') // 获取当前小时数(0-23)
      const minutes = String(now.getMinutes()).padStart(2, 0) // 获取当前分钟数(0-59)
      const seconds = String(now.getSeconds()).padStart(2, 0) // 获取当前秒数(0-59)
      this.nowTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
      console.log('当前时间：', this.nowTime)
    },
    // 回到上一页
    goBack () {
      window.history.back()
    },
    // 跳往红包页
    goHongbao () {
      window.history.go(-2)
    }
  }
}
</script>

<style lang="scss" scoped>
.inline-block {
    display: inline-block;
}
.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #f3f4f6;
  font-size: 15px;
  color: #202122;
  div {
      box-sizing: border-box;
  }
  .header {
    background-color: #fff;
    font-size: 16px;
    color: #202122;
    height: 50px;
    line-height: 50px;
    text-align: center;
    position: fixed;
    width: 100vw;
    font-weight: 600;
    border-bottom: 1px solid #dddddd;
    .back {
      font-weight: 600;
      position: absolute;
      left: 16px;
      top:50%;
      transform: translate(0,-50%);
      font-size: 20px;
    }
    .success {
      font-size: 14px;
      font-weight: 400;
      position: absolute;
      right: 16px;
      top:50%;
      transform: translate(0,-50%);
    }
  }
  .main {
      padding-top: 50px;
      .time-line{
        background-color: #fff;
        padding: 32px 16px 0px;
      }
      .message {
        background-color: #fff;
        padding: 0 16px 20px;
        .money, .bank, .time {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 40px;
        }
      }
  }

}
::v-deep .el-timeline-item__content {
  color: #56BD48;
}
::v-deep .el-timeline-item__tail {
  border-left: 2px solid #56BD48;
}
</style>
