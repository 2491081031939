<template>
  <div class="article">
    <div class="back"></div>
    <img :style="{height: clientWidth}"
         class="img"
         :src="productDetail.imgs"
         alt="">
    <div class="vip">
      <div class="title">{{productDetail.name}}</div>
      <div class="price">
        <div style="color:#FA675C;margin-right: 5px;"><span class="sp1">￥</span><span style="font-size: 20px;"
                class="sp2">{{productDetail.sale_price}}</span></div>
        <div style="color:#808388;text-decoration:line-through "><span class="sp1">￥</span><span style="font-size: 16px;"
                class="sp2">{{productDetail.original_price}}</span></div>
      </div>
    </div>
    <div class="detail">
      <div class="title">详情介绍</div>
      <div v-html="productDetail.desc"></div>
    </div>
    <div style="width:100%;height:100px;"></div>
    <div class="btn"
         @click='createOrder'>
      <div>立即购买</div>
    </div>
    <van-popup v-model="showPopup"
               position="bottom"
               round
               :close-on-click-overlay="true">
      <div class="popup">
        <div class="popup-title">请输入你享用权益的手机号码</div>
        <div class="popup-form">
          <div class="popup-form-title">手机号</div>
          <input maxlength="11"
                 type="text"
                 class="input"
                 v-model="phone"
                 placeholder="请输入手机号" />
        </div>
        <div class="popup-form">
          <div class="popup-form-title">验证码</div>
          <div class="input-content">
            <input maxlength="6"
                   type="text"
                   class="input"
                   v-model="sms_code"
                   placeholder="请输入验证码" />
            <span :style="{ color: !interval ? '#00a0ac' : '#A3A3A3' }"
                  @click="sendMessage">{{ codeBtnStr }}</span>
          </div>
        </div>

        <div class="popup-button"
             @click="login">登录</div>
      </div>
    </van-popup>
    <img @click="toHome"
         style="
         z-index:9999;
          width: 55px;
          height: 55px;
          position: fixed;
          bottom: 200px;
          right: 10px;
          "
         src="@/assets/images/home.png"
         alt="" />
  </div>

</template>
<script>
import { getSmsCode, loginOrRegister, inviteRecord } from '@/api/hongbao'
import { getArticleProductDetail, createGenerateOrder, pay, setProductVisit, addProductVisit } from '@/api/form.js'
import { quickLogin } from '@/api/public.js'

export default {
  components: {},
  props: {},
  data () {
    return {
      clientWidth: '',
      showPopup: false,
      codeBtnStr: '获取验证码',
      interval: 0,
      phone: '',
      sms_code: '',
      loginData: {
        org_id: '',
        phone: '',
        user_code: ''
      },
      productDetail: {
        // product_id: 'Tp164697934045802918660',
        // status: '0',
        // product_type: '0',
        // commission: '1',
        // commission_str: '是',
        // times: 1,
        // name: '需要分佣',
        // imgs: '图片路由',
        // create_by: '创建人名称',
        // relation: 'MST16305710804318637847',
        // desc: '商品详情',
        // sale_price: '3.0',
        // create_time: '2022-03-11 14:1540',
        // original_price: '1800.00',
        // validate_time: 2,
        // role_type: '2'
      }
    }
  },
  watch: {},
  computed: {},
  methods: {
    sendMessage () {
      getSmsCode({
        phone_number: this.phone
      })
        .then((res) => {
          this.interval = 60
          this.timer()
        })
        .catch((err) => {
          this.$toast(err.msg)
        })
    },
    timer () {
      this.interval--
      if (this.interval > 0) {
        this.codeBtnStr = '重新获取(' + this.interval + 's)'
        setTimeout(() => {
          this.timer()
        }, 1000)
      } else {
        this.codeBtnStr = '获取验证码'
      }
    },
    // 登录
    login () {
      if (!this.phone) {
        this.$toast('请输入手机号')
        return
      }
      if (!this.sms_code) {
        this.$toast('请输入验证码')
        return
      }
      loginOrRegister({
        mobile: this.phone, // 手机号码
        sms_code: this.sms_code // 短信验证码
      }).then(res => {
        window.localStorage.setItem('user_sys_info', JSON.stringify(res))
        this.$store.commit('setUserInfo', res)
        // 存本地
        this.loginData = res
        this.showPopup = false
        if (res.login_jump_url === '/sys_org/set_in_org') { // 注册
          this.setInviteRecord()
        }

        this.createOrder()
      }).catch(err => {
        this.$toast(err.msg || '请求失败')
      })
    },
    setInviteRecord () {
      inviteRecord({
        device_info: window.navigator.userAgent, // 手机设备信息
        channel: `TG#${this.loginData.user_code}`, // 渠道码
        scene: '13', // 使用场景 1：进入落地页) 2：不带渠道app) 3:带渠道码app) 4:用户邀请(微信邀请场景) 5:赠送会员) 6:渠道商绑定用户）7:短信邀请）8:临时用户）， 9:代客下单)，10:微信分享app）
        phone: this.phone // 用户手机号码
      })
    },
    createOrder () {
      if (!this.isWx() && !this.loginData.org_id) {
        // 在微信中打开
        this.showPopup = true
        return
      }
      const loading = this.$loading({
        lock: true,
        text: '',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      createGenerateOrder({
        user_code: !this.isWx() ? this.loginData.user_code : this.$route.query.user_code,
        article_name: this.$route.query.article_name,
        record_code: this.$route.query.record_code,
        amount: `${Number(this.productDetail.sale_price).toFixed(2)}`,
        product_id: this.productDetail.product_id,
        user_phone: this.isWx() ? this.$route.query.phone : this.loginData.mobile,
        product_name: this.productDetail.name,
        validate_time: this.productDetail.validate_time,
        is_comm: parseInt(this.productDetail.commission),
        role_type: this.productDetail.role_type,
        uid: this.isWx() ? this.$route.query.uid : ''
      }).then(res => {
        loading.close()
        console.log(res)
        this.toPay(res.data.data.order_code)
      }).catch(err => {
        loading.close()
        this.$toast(err.msg)
        console.log(err)
      })
    },
    toPay (order_code) {
      if (this.isWx()) {
        this.$router.push({
          path: '/pay',
          query: {
            access_token: this.$route.query.access_token,
            nickname: this.$route.query.nickname,
            headimgurl: this.$route.query.headimgurl,
            code: order_code,
            open_id: this.$route.query.open_id,
            uid: this.$route.query.uid,
            user_code: this.$route.query.user_code,
            org_id: this.$route.query.org_id,
            phone: this.$route.query.phone,
            amount: `${Number(this.productDetail.sale_price).toFixed(2)}`,
            receipt_org_id: this.$route.query.plat_org_id,
            article_code: this.$route.query.article_code,
            record_code: this.$route.query.record_code,
            product_id: this.$route.query.product_id,
            appid: 'wxe3e0ce6ec9255a63',
            commission: this.productDetail.commission,
            payType: 'articleProduct',
            payment_type: '101',
            remark: '推广订单'
          }
        })
      } else {
        this.$router.push({
          path: '/pay',
          query: {
            code: order_code,
            phone: this.loginData.mobile,
            amount: `${Number(this.productDetail.sale_price).toFixed(2)}`,
            user_code: this.loginData.user_code,
            org_id: this.loginData.org_id,
            receipt_org_id: this.$route.query.plat_org_id,
            article_code: this.$route.query.article_code,
            record_code: this.$route.query.record_code,
            product_id: this.$route.query.product_id,
            appid: 'wxe3e0ce6ec9255a63',
            commission: this.productDetail.commission,
            payType: 'articleProduct',
            payment_type: '101',
            remark: '推广订单'
          }
        })
      }
    },
    // 跳转主页
    toHome () {
      if (this.isWx()) {
        const query = this.$route.query
        this.$router.push({
          path: '/articleHome',
          query: {
            nickname: query.nickname,
            headimgurl: query.headimgurl,
            open_id: query.open_id,
            uid: query.uid,
            access_token: query.access_token,
            org_id: query.org_id,
            phone: query.phone,
            user_code: query.user_code
          }
        })
      } else {
        this.$router.push({
          path: '/articleHome',
          query: {
            org_id: this.loginData.org_id ? this.loginData.org_id : '',
            phone: this.loginData.mobile ? this.loginData.mobile : '',
            user_code: this.loginData.user_code ? this.loginData.user_code : ''
          }
        })
      }
    },
    isWx () {
      var ua = navigator.userAgent.toLowerCase()// 获取判断用的对象
      return ua.match(/MicroMessenger/i) == 'micromessenger'
    },
    _getArticleProductDetail () {
      getArticleProductDetail({
        article_code: this.$route.query.article_code,
        product_id: this.$route.query.product_id
      }).then(res => {
        console.log(res)
        this.productDetail = res.data.data
        document.title = res.data.data.name
        this._setProductVisit()
        this._addProductVisit()
      }).catch(err => {
        console.log(err)
      })
    },
    _setProductVisit () {
      setProductVisit({
        code: this.$route.query.generate_code, // 总代code
        product_id: this.$route.query.product_id, // 商品id
        product_name: this.productDetail.name,
        article_code: this.$route.query.article_code // 文章id
      }).then(res => {

      }).catch(err => {

      })
    },
    _addProductVisit () {
      addProductVisit({
        generate_code: this.$route.query.generate_code, // 总代code
        product_id: this.$route.query.product_id, // 商品id
        article_code: this.$route.query.article_code // 文章id
      }).then(res => {

      }).catch(err => {
        console.error(err)
      })
    }
  },
  created () {
    document.title = ''
    this.clientWidth = `${document.documentElement.clientWidth}px`
    if (this.$route.query.org_id) {
      quickLogin({
        org_id: this.$route.query.org_id
      }).then(res => {
        window.localStorage.setItem('user_sys_info', JSON.stringify(res))
        this.$store.commit('setUserInfo', res)
      })
    }
    this._getArticleProductDetail()
  },
  mounted () { }
}
</script>

<style lang="scss" scoped>
.article {
  background: #ebeef5;
  width: 100%;
  min-height: 100%;
  .img {
    width: 100%;
    background: #c3bfbf;
  }
  .vip {
    padding: 18px 14px;
    margin-bottom: 15px;
    width: 100%;
    box-sizing: border-box;
    background: #ffffff;
    .img {
      width: 70px;
      height: 70px;
      border-radius: 10px;
      background: #808388;
      margin-right: 6px;
    }
    .title {
      font-size: 15px;
      font-weight: 500;
      color: #202122;
    }
    .price {
      display: flex;
      align-items: flex-end;
      margin-top: 10px;
      .sp1 {
        font-size: 14px;
      }
    }
  }
  .detail {
    background: #fff;
    padding: 14px 14px;
    img {
      width: 100%;
      height: auto;
    }
    .title {
      padding-bottom: 14px;
      font-size: 15px;
      color: #202122;
      font-weight: 700;
    }
  }
  .btn {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 60px;
    padding: 10px;
    box-sizing: border-box;
    background: #fff;
    div {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #00a0ac;
      border-radius: 8px;
      color: #fff;
    }
  }
}
.popup {
  padding: 0 30px;

  .popup-title {
    padding: 25px 0 32px;
    font-size: 16px;
  }

  .popup-form {
    border-bottom: 1px #ddd solid;
    margin-bottom: 10px;

    .input {
      border: none;
      outline: none;
      padding: 8px 0;
      color: #202122;
    }

    .popup-form-title {
      color: #505255;
      font-size: 13px;
    }
  }

  .input-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #00a0ac;
  }

  .popup-button {
    background: #00a0ac;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin: 70px 0 30px;
  }
}
</style>
