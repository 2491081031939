<template>
  <div class="container">
    <div class="hongbao-bc">
      <div class="top-header">
        <i class="back el-icon-arrow-left" @click="goBack"></i>
        现金红包等你来领
    </div>
      <!-- 滚动列表 -->
      <div class="roll-scroll">
        <el-carousel height="30px"
                     direction="vertical"
                     :autoplay="true"
                     indicator-position="none"
                     :interval="2000">
          <el-carousel-item v-for="(item, index) in phoneList"
                            :key="index"
                            class="roll-carousel">
            <ul class="roll-item">
              <li>{{ item.mobile.slice(7,11 )}}拆开红包获得了{{ item.money/100 }}元</li>
            </ul>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 图片 -->
      <img src="@/assets/images/hongbao/Frame2077@2x.png"
           alt=""
           class="bc-image" />
      <img src="@/assets/images/hongbao/open.gif"
           alt=""
           class="open"
           @click="openRedPackage" />
      <!-- 规则 -->
      <div class="rule-btn"
           @click="showRule = true">
        <img src="@/assets/images/hongbao/2398@2x.png"
             alt=""
             class="rule-img" />
      </div>
      <div class="chance">
        <div class="chance-item">{{ timesInfo.total_times || times|| 0 }}次机会</div>
        <img src="@/assets/images/hongbao/Union.png" alt="">
      </div>
    </div>
    <div class="wallet">
      <div class="wallet-content">
        <div class="header">
          <div class="my-wallet">我的钱包</div>
          <div class="record"
               @click="getLogs">参与记录</div>
        </div>
        <div class="money">
          <div class="left">
            <div class="left-top">共领取红包</div>
            <div class="left-bottom">{{ (amountInfo.amount / 100) ||  0 }}元</div>
          </div>
          <!-- 提现 -->
          <div class="right"
               @click="goWithdrawal">
            <img src="@/assets/images/hongbao/2399@2x.png"
                 alt=""
                 style="width: 70px;height: 30px" />
          </div>
        </div>
        <!-- 任务列表 -->
        <div class="mission">
          <div class="mission-title">
            做任务 领红包
          </div>
          <div class="mission-item"
               v-for="(item, index) in missionList"
               :key="index">
            <div class="title">
              <span>{{ item.name }}(+{{ item.times }}次)</span>
              <span v-if="item.key === 'invite_user'"
                    class="process">({{ item.process|| '0/10' }})</span>
            </div>
            <div class="status"
                 :class="{ finished: item.status == 1 }"
                 @click="doMission(item.key,item.status)">{{ item.status == 0 ? '去完成' : '已完成' }}</div>
          </div>
        </div>
      </div>

      <!-- 排行榜-前端假数据 -->
      <div class="rank">
        <div class="header">
          <div class="rank-title">排行榜</div>
        </div>
        <div class="rank-list">
          <div class="rank-item"
               v-for="(item, index) in rankingList"
               :key="index">
            <div class="left">
              <div class="idx">{{ index + 1 }}</div>
              <div class="name">{{ item.mobile|encryption }}</div>
            </div>
            <div class="times">拆了{{item.times}}次红包</div>
            <div class="money">共获得 {{ item.money/100 }} 元</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 参与记录-弹出层 -->
    <el-drawer title=""
               :visible.sync="showLogs"
               direction="btt"
               :withHeader="false"
               size="60%">
      <div class="drawer-content">
        <div class="header">
          <div style="display: flex;justify-content: space-between;">
            <span>参与记录</span>
            <img @click="showLogs = false"
                 style="width: 7vw; height: 7vw"
                 src="@/assets/images/close_black.png" />
          </div>
        </div>
        <div class="center">
          <ul class="hongbao-logs">
            <li style="display: flex;justify-content: space-between;align-items: center;"
                v-for="(item, index) in historyLogs"
                :key="index">
              <div>
                <div class="title">{{ item.type_str }}</div>
                <div class="date">{{ item.create_time }}</div>
              </div>
              <div class="add-money"
                   v-if="item.type === '1' "> + {{ item.amount/100 }}</div>
              <div class="reduce-money"
                   v-else>-{{ item.amount/100}}</div>
            </li>
          </ul>
        </div>
      </div>
    </el-drawer>
    <!-- 成功开红包-弹窗 -->
    <div class="mask"
         v-show="showHongBao" @touchmove.prevent @click.self="showHongBao = false">
      <div class="hongbao-box"
           :class="{bounceIn:showAnimate}">
        <div class="desc">
          <div class="hongbao-content">
            <div>恭喜获得现金红包</div>
            <div>{{redPackageMoney / 100}}<span>元</span></div>
            <div>奖金将存入钱包，可提现</div>
          </div>
        </div>
        <div class="operate">
          <div @click="continueRedPackage">继续开红包</div>
          <div>宝秤新集贸</div>
        </div>
      </div>
    </div>

    <!-- 登录/注册-弹出层 -->
    <div class="mask"
         v-show="showLogin" @touchmove.prevent>
      <div class="bottom-box"
           :class="{bounceOutDown: !showLogin}">
        <div class="top">
          <div style="display: flex;justify-content: space-between;">
            <span>登录/注册</span>
            <img @click="closeLogin"
                 style="width: 7vw; height: 7vw"
                 src="@/assets/images/close_black.png" />
          </div>
          <div class="desc">请先进行注册/登录后可以进行拆红包操作</div>
        </div>
        <div class="bottom">
          <div class="login-input">
            <input type="text"
                   v-model="phone"
                   placeholder="请输入手机号"
                   maxlength="11">
          </div>
          <div class="login-input sms-code">
            <input type="text"
                   style="width: 70%"
                   v-model="sms_code"
                   placeholder="请输入验证码">
            <div :class="{'get-sms-code':  !interval, 'disable-code': interval}"
                 @click="getSmsCode">{{codeBtnStr}}</div>
          </div>
          <div class="login-btn">
            <el-button type="primary"
                       @click="login">登录</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 做任务提示/提现提示/登录提示-弹窗 -->
    <div class="mask" v-if="showModal" @touchmove.prevent>
      <div class="mid-box">
        <div class="desc">
          <p class="title">{{ tipsInfo.name }}</p>
          <p class="tips">{{ tipsInfo.msg }}</p>
        </div>
        <div class="operate">
          <el-button type="primary"
                     @click="operate()">{{ tipsInfo.doneMsg }}</el-button>
          <div class="cancelBtn"
               @click="showModal = false">取消</div>
        </div>
      </div>
    </div>
    <!-- 规则说明-弹出层 -->
    <el-drawer title=""
               :visible.sync="showRule"
               direction="btt"
               :withHeader="false"
               size="60%">
      <div class="drawer-content">
        <div class="header">
          <div style="display: flex;justify-content: space-between;">
            <span>规则说明</span>
            <img @click="showRule = false"
                 style="width: 7vw; height: 7vw"
                 src="@/assets/images/close_black.png" />
          </div>
        </div>
        <div class="center">
          <ul class="regular">
            <li>· 拆红包活动需要宝秤用户才能进行参与</li>
            <li>· 领取的红包可以随时进行提现，不限制最低提现金额。</li>
            <li>· 进行提现操作需要进行实名/企业认证，因为涉及到银行监管问题 </li>
            <li>· 提现需要到宝秤新集贸APP中进行</li>
            <li>· 活动时间内可以进行提现，超过活动时间，将不能进行提现</li>
            <li>· 最终解释权归‘宝秤新集贸’所有</li>
          </ul>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  loginOrRegister,
  getSmsCode,
  redPackageOpen,
  redpackageBase,
  inviteRecord,
  redPackageRecord,
  redPackageShareApp
} from '@/api/hongbao'
import rankingList from '@/assets/json/rankingList.json'
import phoneList from '@/assets/json/phone.json'
import {
  mapGetters
} from 'vuex'

export default {
  metaInfo: {
    title: '瓜分千万现金红包'
  },
  data () {
    return {
      missionList: [
        {
          key: 'org_cert',
          name: '进行企业认证',
          status: '0', // 0-未完成,1-已完成
          times: 1
        },
        {
          key: 'deal_order',
          name: '完成一次采购交易',
          status: '0',
          times: 3
        },
        {
          key: 'invite_user',
          name: '邀请上下游好友',
          status: '0',
          times: 1,
          process: ''
        },
        {
          key: 'share_app',
          name: '分享APP',
          status: '0',
          times: 1
        }
      ],
      tipsInfo: {
        name: '',
        msg: '',
        doneMsg: '',
        type: 100 //  0-提示开通电子钱包，1-提示添加提现银行卡，2-提示去登录，3-提示请在app做任务，100-默认空
      },
      showModal: false, // 窗口开关
      showLogs: false, // 参与记录-开关
      showRule: false, // 规则说明-开关
      showHongBao: false, // 成功开红包-开关
      showLogin: false, // 登录注册-开关
      userRedPackageInfo: {}, // 用户红包信息
      timesInfo: {}, // 红包次数信息
      amountInfo: {}, // 用户余额信息
      taskInfo: {}, // 用户任务信息
      merchantInfo: {}, // 电子钱包+绑卡信息
      historyLogs: [], // 参与记录
      rankingList, // 排行榜
      phoneList, // 滚动轮播
      phone: '', // 电话
      sms_code: '', // 验证码
      redPackageMoney: 0, // 获取到的红包金额,分
      showAnimate: false, // 动画出现
      isUniApp: false, // 是否app打开
      envLoading: true,
      redirectHref: '', // 地址
      interval: 0,
      codeBtnStr: '获取验证码',
      baseInfo: {
        user_code: '',
        org_id: ''
      }
    }
  },
  computed: {
    ...mapGetters(['USER_INFO']),
    times () {
      let result = 0
      if (this.USER_INFO.auth_token) { // 登录
        result = this.timesInfo.total_times
      } else { // 未登录
        result = 1
      }
      return result
    }
  },
  filters: {
    encryption (value) {
      return value.slice(0, 3) + '****' + value.slice(7, 11)
    }
  },
  watch: {
    showAnimate (value) {
      if (value) {
        const timer = setTimeout(() => {
          this.showAnimate = false
          clearTimeout(timer)
        }, 2000)
      }
    }
  },
  created () {
    this.redirectHref = encodeURIComponent(`${window.location.origin}${window.location.pathname}#/hongbao`)
    this.isUniApp = this.$route.query.is_uni_app === '1'
    document.addEventListener('UniAppJSBridgeReady', () => {
      this.envLoading = false
    })
    this.initPage()
  },
  methods: {
    // 回到上一页
    goBack () {
      // window.uni.switchTab({
      //   url: 'pages/home/home'
      // })
      if (this.isUniApp) {
        if (this.envLoading) {
          this.$toast('环境初始化中，请稍后')
        } else {
          window.uni.navigateBack({
            delta: 1
          })
        }
      } else {
        window.history.back()
      }
    },
    // 初始化数据，判断网页版还是app
    initPage () {
      const formatInfo = {
        auth_token: '',
        is_uni_app: false
      }
      formatInfo.auth_token = this.$route.query.auth_token
      formatInfo.is_uni_app = this.$route.query.is_uni_app
      // 判断是网页进入 or APP进来
      if (formatInfo.is_uni_app) { // uniapp进入
        const _userInfo = this.$route.query.sys_org_data.split('*')
        _userInfo.map(item => {
          const list = item.split('-')
          const key = list[0]
          formatInfo[key] = list[1]
        })
        this.$store.commit('setUserInfo', formatInfo)
        // 是否登录
        if (formatInfo.auth_token) {
          this.getUserRedPackageDetail()
        }
      } else { // 网页进入
        this.$store.commit('setUserInfo', JSON.parse(window.localStorage.getItem('user_sys_info') || '{}'))
        if (this.USER_INFO.auth_token) {
          this.getUserRedPackageDetail()
        }
      }
    },
    // 操作
    operate () {
      const p = encodeURIComponent(`merchant_type=${this.merchantInfo.merchant_type}&merchant_id=${this.merchantInfo.merchant_id}&redirectHref=${this.redirectHref}`)
      switch (this.tipsInfo.type) {
        case 0: // 开通电子钱包
          window.uni.navigateTo({
            url: `pages/my/payCenter/openPay?redirectHref=${this.redirectHref}`
          })
          break
        case 1: // 添加提现银行卡
          // 根据钱包类型不同，跳转不同绑卡页面: 1为自然人钱包, 2为个体户钱包, 3为企业钱包(2和3都是企业钱包)
          if (['1', '2'].includes(this.merchantInfo.merchant_type)) {
            window.uni.navigateTo({
              url: `/pages/webview/webview?src=/pay_center/add_withdraw_card&p=${p}`
            })
          } else if (['3'].includes(this.merchantInfo.merchant_type)) {
            window.uni.navigateTo({
              url: `/pages/webview/webview?src=/pay_center/add_corp_card&p=${p}`
            })
          }
          break
        case 2: // 去登录
          window.uni.navigateTo({
            url: '/pages/public/login/login'
          })
          break
        case 3: // 下载App
          this.downloadApp()
          break
      }
    },
    // 点击-提现
    goWithdrawal () {
      // 只能在APP内提现
      if (!this.isUniApp) {
        this.tipsInfo = {
          name: '提现提示',
          msg: '提现请在APP内完成！',
          doneMsg: '下载APP',
          type: 3
        }
        this.showModal = true
        return
      }
      // 未登录
      if (!this.USER_INFO.auth_token) {
        if (this.isUniApp) {
          window.uni.navigateTo({
            url: '/pages/public/login/login'
          })
        } else {
          this.showLogin = true
        }
        return
      }
      // 没有获取到用户钱包信息
      if (!Object.keys(this.merchantInfo).length) {
        this.$toast('获取电子钱包信息异常')
        return
      }
      // 是否开通电子钱包
      if (this.merchantInfo.is_merchant === '0') {
        this.showModal = true
        this.tipsInfo = {
          name: '提现提示',
          msg: '提现需要先开通电子钱包',
          doneMsg: '马上开通',
          type: 0
        }
        return false
      }
      // 是否绑定了银行卡
      if (this.merchantInfo.is_bind === '0') {
        this.showModal = true
        this.tipsInfo = {
          name: '提现提示',
          msg: '提现需要先添加提现银行卡',
          doneMsg: '马上添加',
          type: 1
        }
        return false
      }
      // 跳转-提现页
      this.$router.push({
        path: 'Withdrawal',
        query: {
          amount: this.amountInfo.amount // 分
        }
      })
    },
    // 点击-开红包
    async openRedPackage () {
      // 判断是否登录
      try {
        if (!this.USER_INFO.auth_token) {
          // app中
          if (this.isUniApp) {
            window.uni.navigateTo({
              url: '/pages/public/login/login'
            })
          } else {
            this.showLogin = true
          }
        } else { // 已登录, 获取用户红包信息
          await this.getUserRedPackageDetail()
          if (this.timesInfo.total_times > 0) {
            this.getRedPackage()
          } else {
            this.$toast('暂无领取次数，做任务可增加次数')
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    // 获取用户红包信息
    async getUserRedPackageDetail () {
      await redpackageBase({
        user_code: this.USER_INFO.user_code,
        org_id: this.USER_INFO.org_id
      }).then(res => {
        this.userRedPackageInfo = res.data.data
        const { times_info: timesInfo, amount_info: amountInfo, task_info: taskInfo, merchant_info: merchantInfo } = res.data.data
        this.timesInfo = timesInfo
        this.amountInfo = amountInfo
        this.taskInfo = taskInfo
        this.merchantInfo = merchantInfo
        this.missionList.map(item => {
          item.status = this.taskInfo[item.key].status
          item.process = this.taskInfo[item.key].process
        })
      }).catch(err => {
        console.error(err)
        this.$toast(err.msg)
      })
    },
    // 拆红包
    getRedPackage () {
      redPackageOpen({
        user_code: this.USER_INFO.user_code
      }).then(res => {
        this.showHongBao = true // 否则弹出红包
        this.showAnimate = true
        this.redPackageMoney = res.data.data.amount
        this.getUserRedPackageDetail()
      }).catch(err => {
        console.error(err)
        this.$toast(err.msg || '请求失败')
      })
    },
    // 继续开红包
    continueRedPackage () {
      this.showHongBao = false
    },
    // 获取验证码
    getSmsCode () {
      if (this.interval) {
        return
      }
      // 验证手机号
      const phoneReg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/ // 手机号正则
      const result = phoneReg.test(this.phone)
      if (!result) {
        this.$toast('请输入正确的手机号')
        return
      }
      getSmsCode({
        phone_number: this.phone,
        template_id: '5' // 登录确认验证码(登录接口用这个)
      }).then(res => {
        this.interval = 60
        this.timer()
      }).catch(err => {
        console.error(err)
        this.$toast(err.msg)
      })
    },
    timer () {
      this.interval--
      if (this.interval > 0) {
        this.codeBtnStr = '重新获取(' + this.interval + 's)'
        setTimeout(() => {
          this.timer()
        }, 1000)
      } else {
        this.codeBtnStr = '获取验证码'
      }
    },
    // 登录
    login () {
      if (!this.phone) {
        this.$toast('请输入手机号')
        return
      }
      if (!this.sms_code) {
        this.$toast('请输入验证码')
        return
      }
      loginOrRegister({
        mobile: this.phone, // 手机号码
        sms_code: this.sms_code // 短信验证码
      }).then(res => {
        // 存本地
        window.localStorage.setItem('user_sys_info', JSON.stringify(res))
        this.$store.commit('setUserInfo', res)
        this.showLogin = false
        // 登陆成功后，判断是否有拆红包次数
        this.getUserRedPackageDetail()
        if (res.login_jump_url === '/sys_org/set_in_org') { // 注册
          this.setInviteRecord()
        }
      }).catch(err => {
        this.$toast(err.msg || '请求失败')
      })
    },
    // 关闭登录弹窗
    closeLogin () {
      this.showLogin = false
      this.phone = ''
      this.sms_code = ''
    },
    // 设置用户来源
    setInviteRecord () {
      inviteRecord({
        device_info: window.navigator.userAgent, // 手机设备信息
        channel: '8n4Gyrax', // 渠道码
        scene: '13', // 使用场景 1：进入落地页) 2：不带渠道app) 3:带渠道码app) 4:用户邀请(微信邀请场景) 5:赠送会员) 6:渠道商绑定用户）7:短信邀请）8:临时用户）， 9:代客下单)，10:微信分享app）
        phone: this.phone // 用户手机号码
      }).then(res => {

      }).catch(err => { console.error(err) })
    },
    // 点击-做任务
    doMission (key, status) {
      // 任务功能只能在app内触发
      if (status === '0') {
        // 是否app打开
        if (this.isUniApp) {
          // 是否登录
          if (!this.USER_INFO.auth_token) {
            window.uni.navigateTo({
              url: '/pages/public/login/login'
            })
            return
          }
          switch (key) {
            case 'org_cert': // 进行企业认证
              window.uni.navigateTo({
                url: `/pages/price/auth/license?redirectHref=${this.redirectHref}`
              })
              break
            case 'deal_order': // 完成一次采购交易
              window.uni.switchTab({
                url: '/pages/purchase/purchase'
              })
              break
            case 'invite_user': // 邀请上下游好友
              window.uni.navigateTo({
                url: '/pages/my/friends/addFriends'
              })
              break
            case 'share_app': // 分享APP
              redPackageShareApp({
                user_code: this.USER_INFO.user_code
              }).then(res => {
                // 刷新页面
                this.getUserRedPackageDetail()
                window.uni.postMessage({
                  data: {
                    action: 'postMessage',
                    type: 'shareWX'
                  }
                })
              }).catch(err => {
                console.error(err)
                this.$toast(err.msg)
              })
              break
          }
        } else { // h5打开
          this.showModal = true
          this.tipsInfo = {
            name: '任务提示',
            msg: '做任务请在APP内完成！',
            doneMsg: '下载APP',
            type: 3
          }
        }
      }
    },
    // 查看-参与记录
    getLogs () {
      if (!this.USER_INFO.auth_token) {
        window.uni.navigateTo({
          url: '/pages/public/login/login'
        })
        return
      }
      this.showLogs = true
      redPackageRecord({
        user_code: this.USER_INFO.user_code
      }).then(res => {
        this.historyLogs = res.data.data
      }).catch(err => {
        this.$toast(err.msg)
      })
    },
    // 网页中：下载app
    async downloadApp () {
      // 跳转落地页
      window.open('https://static-bc.suzi.com.cn/static/channel/new_market_static/invite_install.html?channel=8n4Gyrax', '_self')
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  box-sizing: border-box;
}

 .top-header {
    z-index: 999;
    font-size: 16px;
    // color: #FFFFFF;
    background-color: #fff;
    height: 50px;
    line-height: 50px;
    text-align: center;
    position: fixed;
    width: 100vw;
    font-weight: 600;
    .back {
        font-weight: 600;
        font-size: 20px;
        position: absolute;
        left: 16px;
        top:50%;
        transform: translate(0,-50%);
    }
  }

.bounceIn {
  animation: bounceIn 0.5s infinite;
  animation-iteration-count: 1;
}
.bounceOut {
  animation: bounceIn 0.3s infinite;
  animation-iteration-count: 1;
}

// 抽屉
::v-deep .el-drawer {
  background: #ffffff;
  border-radius: 20px 20px 0px 0px;
}
.drawer-content {
  padding: 16px;
  .header {
    font-size: 18px;
    font-weight: 500;
    padding: 6px 0;
    position: fixed;
    width: calc(100% - 32px);
    background-color: white;
  }
  .center {
    padding: 16px 0;
    margin-top: 20px;
    // 规则
    .regular {
      font-size: 14px;
      line-height: 26px;
      color: #202122;
    }
    // 参与记录
    .hongbao {
      padding: 0 !important;
    }
    .hongbao-logs {
      max-height: 120vw;
      overflow-y: auto;
      li {
        border-bottom: 0.5px solid #dddddd;
        height: 61px;
        box-sizing: border-box;
        .title {
          font-size: 14px;
          color: #202122;
        }
        .date {
          color: #808388;
          font-size: 12px;
        }
        .add-money {
          color: #fa675c;
          font-size: 14px;
        }
        .reduce-money {
          color: #56bd48;
          font-size: 14px;
        }
      }
    }
  }
}

// =========roll
.roll-scroll {
  width: 60vw;
  // border:  1px solid blue;
  position: absolute;
  left: 10px;
  top: 40%;
  z-index: 9;
}
.roll-carousel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.roll-item {
  color: white;
  border-radius: 20px;
  background-color: rgba(76, 78, 78, 0.5);
  font-size: 14px;
  padding: 4px 6px;
}
// ============roll
html::-webkit-scrollbar,body::-webkit-scrollbar,.container::-webkit-scrollbar{
  display: none;
  width: 0 !important;
  height: 0 !important;
  -webkit-appearance: none;
  background: transparent;
}
.container {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;

  .hongbao-bc {
    width: 100vw;
    position: relative;
    .scroll-list {
      position: absolute;
      left: 2vw;
      top: 40%;
      color: white;
      border-radius: 20px;
      background-color: rgba(76, 78, 78, 0.5);
      font-size: 14px;
      padding: 4px 6px;
    }
    .bc-image {
      width: 100%;
      z-index: 1;
    }

    .open {
      width: 193px;
      position: absolute;
      left: 50%;
      bottom: 28vw;
      transform: translateX(-50%);
      z-index: 10;
    }

    .rule-btn {
      width: 26px;
      height: 62px;
      position: absolute;
      right: 0;
      bottom: 215px;
      .rule-img {
        width: 100%;
        height: 100%;
      }
    }

    .chance {
      position: absolute;
      color: #944118;
      left: 24vw;
      bottom: 40vw;
      z-index: 11;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 28px;
      img {
        height: 100%;
      }
      .chance-item {
        position: absolute;
        padding-bottom: 4px;
        font-weight: 600;
        font-size: 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

  }

  .wallet {
    padding: 0 16px 16px;
    background: linear-gradient(180deg, #ffa944, #ffe6c8 100%);

    .wallet-content {
      background-color: #fff5e9;
      border-radius: 8px;
      overflow: hidden;

      .header {
        padding: 11px 0 8px 11px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .my-wallet {
          font-size: 18px;
          color: #944118;
          font-weight: bold;
        }

        .record {
          padding: 4px 5px 4px 11px;
          color: #944118;
          font-size: 12px;
          background-color: #fce7ce;
          border-top-left-radius: 11px;
          border-bottom-left-radius: 11px;
        }
      }

      .money {
        padding: 10px 11px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;

        .left {
          .left-top {
            font-size: 12px;
            color: #808388;
          }

          .left-bottom {
            color: #df1500;
            font-size: 18px;
            font-weight: bold;
          }
        }
      }
      .mission {
        margin-top: 10px;
        background-color: #fff;

        .process {
          padding: 0 20px;
          color: #808388;
          font-size: 12px;
        }
        .mission-title {
          color: #944118;
          font-size: 18px;
          font-weight: bold;
          padding: 10px;
        }

        .mission-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 20px 12px;
          border-top: 0.5px solid #ddd;
          .title {
            font-size: 15px;
          }

          .status {
            font-size: 15px;
            color: #fff;
            background-color: #fd5249;
            padding: 6px 14px;
            border-radius: 16px;
          }

          .finished {
            background-color: #ffdad8;
          }
        }
      }
    }

    .rank {
      margin-top: 16px;
      background-color: #fff5e9;
      border-radius: 8px;
      overflow: hidden;

      .header {
        padding: 11px 0 8px 11px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .rank-title {
          font-size: 18px;
          color: #944118;
          font-weight: bold;
        }
      }

      .rank-list {
        background-color: #ffffff;
        .rank-item {
          display: flex;
          justify-content: space-between;
          margin-left: 12px;
          padding: 15px 8px 15px 0;
          .left {
            display: flex;
            align-items: center;
            width: 36%;
            .idx {
              margin-right: 5px;
              display: flex;
              background-color: #fde5c9;
              border-radius: 50%;
              width: 16px;
              height: 16px;
              font-size: 10px;
              color: #f0b26c;
              justify-content: center;
              align-items: center;
            }
          }

          .times {
            color: #505255;
            font-size: 13px;
            width: 30%;
          }

          .money {
            color: #202122;
            font-size: 13px;
            width: 34%;
          }
        }

        .rank-item:not(:last-child) {
          border-bottom: 0.5px solid #ddd;
        }
      }
    }
  }
}

.mask {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 12;

  // 抢红包成功
  .hongbao-box {
    width: 83vw;
    height: 104vw;
    margin: 0 8.5vw;
    background: linear-gradient(180deg, #ff4930, #e31814 100%);
    border-radius: 11px;
    box-shadow: 0px 8.98px 11.23px 0px rgba(255, 207, 134, 0.3) inset;
    position: fixed;
    top: 50%;
    transform: translate(0, -50%);
    .desc {
      width: 77vw;
      height: 79vw;
      margin: 13px 3vw 0;
      background: linear-gradient(132deg, #ffecd1 0%, #e4916d 100%);
      border-radius: 9px;
      display: flex;
      justify-content: center;
      align-items: center;
      .hongbao-content {
        width: calc(100% - 14px);
        height: calc(100% - 14px);
        padding: 36px 0;
        border: 1.12px solid;
        border-image: linear-gradient(132deg, #f5dcb8 0%, #d3805c 100%)
          1.1225626468658447 1.1225626468658447;
        border-radius: 9px;
        font-family: PingFang SC, PingFang SC-Semibold;
        & div:nth-of-type(1) {
          font-size: 18px;
          font-weight: 600;
          text-align: CENTER;
          color: #92491e;
        }
        & div:nth-of-type(2) {
          font-size: 60px;
          font-weight: 600;
          text-align: CENTER;
          color: #cd0f0c;
          margin: 10px 0 17px 0;
          span {
            font-size: 16px;
            font-weight: 400;
          }
        }
        & div:nth-of-type(3) {
          width: 42vw;
          height: 8vw;
          line-height: 8vw;
          margin: 0 auto;
          border: 0.5px solid #d28b60;
          border-radius: 15.5px;

          font-size: 12px;
          font-weight: 400;
          text-align: CENTER;
          color: #92491e;
        }
      }
    }
    .operate {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-image: url("../../assets/images/hongbao/Frame\ 2072@2x.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      & div:nth-of-type(1) {
        width: 56vw;
        height: 15.2vw;
        line-height: 15.2vw;
        margin-top: 16.4vw;
        background: linear-gradient(180deg, #ffdda0, #ffc155 100%);
        border-radius: 56px;
        box-shadow: 0px 11.23px 5.61px -5.61px #ce2010;
        font-size: 20px;
        font-weight: 600;
        text-align: CENTER;
        color: #7b1812;
      }
      & div:nth-of-type(2) {
        font-size: 16px;
        font-weight: 400;
        text-align: CENTER;
        color: #ff5c57;
        padding-top: 15px;
        padding-bottom: 17px;
      }
    }
  }
  // 弹窗
  .mid-box {
    margin: 0 10vw;
    width: 80vw;
    position: fixed;
    top: 50%;
    transform: translate(0, -50%);
    background: #ffffff;
    border-radius: 8px;
    text-align: center;
    padding: 24px 16px 10px;
    box-sizing: border-box;
    .desc {
      padding-bottom: 30px;
      .title {
        font-size: 18px;
        color: #202122;
        font-weight: 600;
        font-family: PingFang SC, PingFang SC-Medium;
        padding-bottom: 14px;
      }
      .tips {
        font-size: 16px;
        color: #202122;
      }
    }
    .operate {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .el-button,
      .cancelBtn {
        width: 268px;
        height: 36px;
        margin: 0;
      }
      .cancelBtn {
        margin-top: 14px;
        line-height: 36px;
        font-size: 14px;
        color: #808388;
      }
    }
  }
  // 弹出层
  .bottom-box {
    width: 100%;
    background: #fff;
    position: fixed;
    bottom: 0px;
    border-top-left-radius: 4vw;
    border-top-right-radius: 4vw;
    .top {
      // display: flex;
      // justify-content: space-between;
      padding: 4vw;
      span {
        font-size: 18px;
        font-weight: bold;
        color: #17191c;
      }
    }
    .desc {
      color: #808388;
      font-size: 12px;
    }
    .bottom {
      display: flex;
      flex-direction: column;
      padding: 15px 16px;

      .login-input {
        border-bottom: 0.5px solid #ddd;
        line-height: 50px;
        input {
          border: none;
          width: 100%;
        }
      }

      .sms-code {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        .get-sms-code {
          color: #00a0ac;
          width: 83px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border: 0.5px solid #00a0ac;
          border-radius: 4px;
        }
        .disable-code {
          color: #a3a3a3;
        }
      }
      .login-btn {
        padding: 36px 0 14px 0;
        .el-button--primary {
          width: 343px;
        }
      }
    }
  }
}
.el-button--primary {
  background: #00a0ac;
  border: none;
  font-size: 16px;
}
// 动画
@keyframes bounceIn {
  0% {
    opacity: 0.5;
    transform: translate(0, -50%) scale(0.2);
  }
  100% {
    opacity: 1;
    transform: translate(0, -50%) scale(1);
  }
}
@keyframes bounceOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.6);
  }
}
</style>
