<template>
  <van-dialog v-model="show" title="确认收款" :width="300" :show-confirm-button="false">
    <div class="dialog">
      <p class="dialog-content">{{amount ? `是否确认已收到运费，合计¥${amount}？` : '是否选中的物流单已收款？'}}</p>
      <div class="dialog-footer">
        <div class="dialog-footer-button button-cancel" @click="show = false">取消</div>
        <div class="dialog-footer-button" @click="confirm">确已认收款</div>
      </div>
    </div>
  </van-dialog>
</template>

<script>
import { createSettleOrder, createPaymentOrder, createExpressBalance } from '@/api/public'

export default {
  data () {
    return {}
  },
  props: {
    value: Boolean,
    amount: String,
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    confirm () {
      const promiseList = []
      this.list.forEach((el) => {
        promiseList.push(this.createSettle(el))
      })
      Promise.all(promiseList)
        .then((res) => {
          this.next()
        })
        .catch((err) => {
          this.$toast(err.msg || '请求失败')
        })
    },
    next () {
      createExpressBalance({
        express_id_list: this.list.map(el => el.express_id)
      }).then(() => {
        this.show = false
        this.$emit('success')
      }).catch((err) => {
        this.$toast(err.msg || '请求失败')
      })
    },
    createPayment (item) {
      return createPaymentOrder({
        user_code: item.express_user_code,
        code: item.settle_code,
        co_amount: item.re_amount, // 本次支付金额
        payment_type: '1', // ':货物支付 "1": 第三方运费支付 "2": 自营发货单运费支付 "3"：应收应付
        payment_channel: '1', // 支付方式 '0':线上支付 '1':线下支付
        payment_aisle: '5', // :空 '1':支付宝 '2':现金 '3':银行转账 '4':微信 5:其他
        voucher: [],
        payment_amount: '0.00', // 剩余需要支付的金额 没有传0.00
        remark: '',
        org_id: item.express_org_id
      })
    },
    createSettle (item) {
      return new Promise((resolve, reject) => {
        createSettleOrder({
          seller_org: item.express_org_id,
          buyer_org:
            item.logistics_freight_pay === 'buyer'
              ? item.buyer_org_id
              : item.order_org_id,
          settle_org: item.express_org_id,
          receive_amount: item.re_amount,
          pay_amount: item.re_amount,
          amount: item.re_amount,
          settle_type: '1',
          other_amount: '0',
          advance_list: [],
          balance_list: [item.balance]
        }).then((res) => {
          // console.error(res.data.data, 'res')
          item.settle_code = res.data.data.settle_code
          this.createPayment(item).then(resolve).catch(reject)
        }).catch(reject)
      })
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  padding: 24px 0 22px;

  .dialog-content {
    padding: 0 60px 20px;
    text-align: center;
    color: #202122;
  }
  .dialog-footer {
    display: flex;
    justify-content: center;

    .dialog-footer-button {
      height: 36px;
      width: 117px;
      border-radius: 4px;
      background: #00a0ac;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .button-cancel {
      margin-right: 20px;
      background: #f3f4f6;
      color: #00a0ac;
    }
  }
}
</style>
