// import { app } from '@/plugins/appAxios'
// import { api } from '@/plugins/axios'
import { mangeApi } from '@/plugins/mangeAxios'

// 获取可提现金额
export function getWalletDetail (params) {
  return mangeApi('/generate/generate_comm/total', params)
}

// 推广记录奖励
export function getTradLog (params) {
  return mangeApi('/generate/generate_comm/record', params)
}
