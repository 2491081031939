<template>
  <div>
    <div class="navbar">
      <img
        src="@/assets/images/back_black.png"
        class="navbar_back"
        @click="toBack"
      />
      <span class="navbar_title">申请成为总代理</span>
      <span></span>
    </div>

    <div class="content flex-center">
      <div class="main">
        <img src="@/assets/images/success.png" alt class="status-icon" />
        <div class="tips default success">提交成功，等待审核中</div>
        <div>
          <p>请等待审核，预计1工作日将会审核完毕,</p>
          <p>审核成功后，相关人员会及时联系你。</p>
        </div>
        <div class="tips download" @click="goDownload()">下载APP></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isUniApp: false,
      envLoading: true
    }
  },
  created () {
    document.title = '审核总代'
    this.isUniApp = !!this.$route.query.auth_token
    document.addEventListener('UniAppJSBridgeReady', () => {
      this.envLoading = false
    })
  },
  methods: {
    toBack () {
      if (this.isUniApp) {
        if (this.envLoading) {
          this.$toast('环境初始化中，请稍后')
        } else {
          window.uni.navigateBack({
            delta: 1
          })
        }
      } else {
        window.history.back()
      }
    },
    goDownload () {
      this.$router.push({
        name: 'Download'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.default {
  color: #00a0ac;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.full-w {
  width: 100%;
}
.content {
  height: calc(100vh - 13.3333vw);
  background-color: #f1f1f1;
}
.main {
  text-align: center;
}
.tips {
  font-size: 16px;
}

p{
  line-height: 23px;
  font-size: 14px;
  color: #202122;
}
.download {
  padding-top: 49px;
  color: #009EAA;
  font-size: 16px;
}
.success {
  padding: 5px 0 20px 0;
}
.status-icon {
  width: 46px;
  height: 46px;
}
</style>
