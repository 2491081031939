<template>
  <div>
    <div class="navbar">
      <img src="@/assets/images/back_black.png" class="navbar_back" @click="toBack" />
      <span class="navbar_title">招募海报</span>
      <span></span>
    </div>
    <div class="content">
      <img src="@/assets/images/H5_02.jpg" alt />
      <img src="@/assets/images/H5_03.jpg" alt />
      <div class="btn">
        <el-button type="primary" class="submitBtn" @click="goApply">申请成为总代理</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isUniApp: false,
      envLoading: true
    }
  },
  created () {
    document.title = '招募总代海报'
    this.isUniApp = !!this.$route.query.auth_token
    document.addEventListener('UniAppJSBridgeReady', () => {
      this.envLoading = false
    })
  },
  methods: {
    toBack () {
      if (this.isUniApp) {
        if (this.envLoading) {
          this.$toast('环境初始化中，请稍后')
        } else {
          window.uni.navigateBack({
            delta: 1
          })
        }
      } else {
        window.history.back()
      }
    },
    goApply () {
      this.$router.push({
        name: 'AgentApply'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  box-sizing: border-box;
}
img {
  max-width: 100%;
}
.content {
  padding-bottom: 40px;
}
.btn {
  width: 100%;
  padding: 5px 15px;
  position: fixed;
  bottom: 0px;
  background-color: #fff;
  .submitBtn {
    width: 100%;
    background: #00A0AC;
    border: none;
    border-radius: 4px;
  }
}
</style>
