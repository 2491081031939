<template>
  <div>
    <div class="header">
      <div class="header-back" @click="toBack">
        <img src="@/assets/images/prev_blank.png">
      </div>
      <span style="font-weight: bold"> 广西百色专场 </span>
      <div class="header-tag">活动已结束</div>
    </div>

    <div class="content">
      <img src="@/assets/images/baise/头图.png" alt="" />

      <van-tabs
        v-model="rankActive"
        background="#118f51"
        color="#fff"
        title-inactive-color="#fff"
        title-active-color="#fff"
        line-width="6.1333vw"
      >
        <van-tab title="商品排行" title-style="font-size: 18PX">
          <div class="top">
            <img
              src="@/assets/images/baise/热销商品1.png"
              @click="
                toProduct(
                  'PI163378369711408',
                  'O163378031960461',
                  'PU163378369711474'
                )
              "
              class="rank-product"
            />
            <img
              src="@/assets/images/baise/热销商品2.png"
              @click="
                toProduct(
                  'PI163378369593432',
                  'O163377948212822',
                  'PU163378369593494'
                )
              "
              class="rank-product"
            />
            <img
              src="@/assets/images/baise/热销商品3.png"
              @click="
                toProduct(
                  'PI163378369590885',
                  'O163377946627264',
                  'PU163378369590946'
                )
              "
              class="rank-product"
            />
            <img
              src="@/assets/images/baise/热销商品4.png"
              @click="
                toProduct(
                  'PI163378369589082',
                  'O163377945267266',
                  'PU163378369589141'
                )
              "
              class="rank-product"
            />
            <img
              src="@/assets/images/baise/热销商品5.png"
              @click="
                toProduct(
                  'PI163378369717261',
                  'O163378035336687',
                  'PU163378369717331'
                )
              "
              class="rank-product"
            />
          </div>
        </van-tab>
        <van-tab title="店铺排行" title-style="font-size: 18PX">
          <div class="top">
            <img
              src="@/assets/images/baise/店铺排行1.png"
              @click="toStore('O163377959063458')"
              class="rank-product"
            />
            <img
              src="@/assets/images/baise/店铺排行2.png"
              @click="toStore('O163378028143819')"
              class="rank-product"
            />
            <img
              src="@/assets/images/baise/店铺排行3.png"
              @click="toStore('O163377946852338')"
              class="rank-product"
            />
            <img
              src="@/assets/images/baise/店铺排行4.png"
              @click="toStore('O163377963394509')"
              class="rank-product"
            />
            <img
              src="@/assets/images/baise/店铺排行5.png"
              @click="toStore('O163377964071677')"
              class="rank-product"
            />
          </div>
        </van-tab>
      </van-tabs>

      <div class="top">
        <div class="time-box">
          <img src="@/assets/images/baise/1838.png" alt="" class="time-bg" />
          <div class="time-content">
            <img
              src="@/assets/images/baise/限时商品1.png"
              @click="
                toProduct(
                  'PI163378369608131',
                  'O163377957919931',
                  'PU163378369608192'
                )
              "
              class="time-product"
            />
            <img
              src="@/assets/images/baise/限时商品2.png"
              @click="
                toProduct(
                  'PI163378369610344',
                  'O163377959291839',
                  'PU163378369610407'
                )
              "
              class="time-product"
            />
            <img
              src="@/assets/images/baise/限时商品3.png"
              @click="
                toProduct(
                  'PI163378369717261',
                  'O163378035336687',
                  'PU163378369717331'
                )
              "
              class="time-product"
            />
            <img
              src="@/assets/images/baise/限时商品4.png"
              @click="
                toProduct(
                  'PI163378369704880',
                  'O163378028143819',
                  'PU163378369704950'
                )
              "
              class="time-product"
            />

            <img
              src="@/assets/images/baise/限时商品5.png"
              @click="
                toProduct(
                  'PI163378369599887',
                  'O163377952258528',
                  'PU163378369599960'
                )
              "
              class="time-product"
            />
            <img
              src="@/assets/images/baise/限时商品6.png"
              @click="
                toProduct(
                  'PI163378369721814',
                  'O163378038038709',
                  'PU163378369721879'
                )
              "
              class="time-product"
            />
            <img
              src="@/assets/images/baise/限时商品7.png"
              @click="
                  toProduct(
                    'PI163378369724479',
                    'O163378040956348',
                    'PU163378369724540'
                  )
                "
              class="time-product"
            />
            <img
              src="@/assets/images/baise/限时商品8.png"
              @click="
                toProduct(
                  'PI163378369637621',
                  'O163377983036947',
                  'PU163378369637688'
                )
              "
              class="time-product"
            />
            <img
              src="@/assets/images/baise/限时商品9.png"
              @click="
                toProduct(
                  'PI163378369470399',
                  'O163377833150802',
                  'PU163378369470462'
                )
              "
              class="time-product"
            />
            <img
              src="@/assets/images/baise/限时商品10.png"
              @click="
                toProduct(
                  'PI163378369723731',
                  'O163378039160006',
                  'PU163378369723796'
                )
              "
              class="time-product"
            />

            <div style="width: 0.2667vw;height:2.6667vw;flex-shrink:0"></div>
          </div>
        </div>

        <div style="height:3.2vw;width:100%"></div>
        <div class="store">
          <p class="store-title">特色店铺</p>
          <van-swipe class="my-swipe" :loop="false" indicator-color="#E06F18">
            <van-swipe-item>
              <div style="padding-left: 2.1333vw">
                <div class="store-list">
                  <img
                    src="@/assets/images/baise/特产店铺1.png"
                    @click="toStore('O163377777661665')"
                    class="store-product"
                  />

                  <img
                    src="@/assets/images/baise/特产店铺2.png"
                    @click="toStore('O163377958609382')"
                    class="store-product"
                  />

                  <img
                    src="@/assets/images/baise/特产店铺3.png"
                    @click="toStore('O163378033538255')"
                    class="store-product"
                  />

                  <img
                    src="@/assets/images/baise/特产店铺4.png"
                    @click="toStore('O163378035336687')"
                    class="store-product"
                  />
                </div>
              </div>
            </van-swipe-item>
            <van-swipe-item>
              <div style="padding-left: 2.1333vw">
                <div class="store-list">
                  <img
                    src="@/assets/images/baise/特产店铺5.png"
                    @click="toStore('O163378037592622')"
                    class="store-product"
                  />

                  <img
                    src="@/assets/images/baise/特产店铺6.png"
                    @click="toStore('O163377833150802')"
                    class="store-product"
                  />

                  <img
                    src="@/assets/images/baise/特产店铺7.png"
                    @click="toStore('O163377982586709')"
                    class="store-product"
                  />

                  <img
                    src="@/assets/images/baise/特产店铺8.png"
                    @click="toStore('O163378037816363')"
                    class="store-product"
                  />
                </div>
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>

      <div class="tab-box">
        <van-tabs
          v-model="active"
          color="#118F51"
          background="#F3F4F6"
          title-inactive-color="#808388"
          title-active-color="#118F51"
          line-width="3.2vw"
          line-height="0.5333vw"
        >
          <van-tab title="芒果" title-style="font-size: 16PX">
            <div class="tab-list">
              <img
                src="@/assets/images/baise/芒果1.png"
                @click="
                  toProduct(
                    'PI163378369593432',
                    'O163377948212822',
                    'PU163378369593494'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/芒果2.png"
                @click="
                  toProduct(
                    'PI163378369608131',
                    'O163377957919931',
                    'PU163378369608192'
                  )
                "
                class="tab-product"
              />
              <!-- <img
                src="@/assets/images/baise/芒果3.png"
                @click="
                  toProduct(
                    'PI163378369612217',
                    'O163377961565608',
                    'PU163525198454968'
                  )
                "
                class="tab-product"
              /> -->
              <img
                src="@/assets/images/baise/芒果4.png"
                @click="
                  toProduct(
                    'PI163378369641319',
                    'O163377985527019',
                    'PU163378369641381'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/芒果5.png"
                @click="
                  toProduct(
                    'PI163378369610344',
                    'O163377959291839',
                    'PU163378369610407'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/芒果6.png"
                @click="
                  toProduct(
                    'PI163378369609971',
                    'O163377959063458',
                    'PU163378369610031'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/芒果7.png"
                @click="
                  toProduct(
                    'PI163378369609595',
                    'O163377958836737',
                    'PU163378369609657'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/芒果8.png"
                @click="
                  toProduct(
                    'PI163378369609225',
                    'O163377958609382',
                    'PU163378369609288'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/芒果9.png"
                @click="
                  toProduct(
                    'PI163378369608500',
                    'O163377958153135',
                    'PU163378369608559'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/芒果10.png"
                @click="
                  toProduct(
                    'PI163378369596813',
                    'O163377950233910',
                    'PU163378369596880'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/芒果11.png"
                @click="
                  toProduct(
                    'PI163378369394332',
                    'O163377777661665',
                    'PU163378369394397'
                  )
                "
                class="tab-product"
              />
            </div>
          </van-tab>
          <van-tab title="柚子" title-style="font-size: 16PX">
            <div class="tab-list">
              <img
                src="@/assets/images/baise/柚子1.png"
                @click="
                  toProduct(
                    'PI163378369637621',
                    'O163377983036947',
                    'PU163378369637688'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/柚子2.png"
                @click="
                  toProduct(
                    'PI163705459926712233114',
                    'O163377974478867',
                    'PU163705512765581229624'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/柚子3.png"
                @click="
                  toProduct(
                    'PI163705468419965513419',
                    'O163377974255087',
                    'PU163705530372160206833'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/柚子4.png"
                @click="
                  toProduct(
                    'PI163705467236445910208',
                    'O163377957468393',
                    'PU163705467236793459325'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/柚子5.png"
                @click="
                  toProduct(
                    'PI163378369724479',
                    'O163378040956348',
                    'PU163378369724540'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/柚子6.png"
                @click="
                  toProduct(
                    'PI16370542966804628958',
                    'O163377979222909',
                    'PU163705429668544369050'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/柚子7.png"
                @click="
                  toProduct(
                    'PI163672081153237841961',
                    'O163377947307464',
                    'PU163672081153656990779'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/柚子8.png"
                @click="
                  toProduct(
                    'PI163672091822251265641',
                    'O163377979896762',
                    'PU163672091822631943737'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/柚子9.png"
                @click="
                  toProduct(
                    'PI163672101943711182171',
                    'O163377962251211',
                    'PU163672101944072711351'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/柚子10.png"
                @click="
                  toProduct(
                    'PI163672111920973104739',
                    'O163377962024831',
                    'PU163672111921333423483'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/柚子11.png"
                @click="
                  toProduct(
                    'PI163672128286316629400',
                    'O163377755133270',
                    'PU163672128286672785695'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/柚子12.png"
                @click="
                  toProduct(
                    'PI163672140160961190778',
                    'O163378045455925',
                    'PU163672140161339947481'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/柚子13.png"
                @click="
                  toProduct(
                    'PI163672150495555162895',
                    'O163377721653822',
                    'PU163672150495931165572'
                  )
                "
                class="tab-product"
              />
            </div>
          </van-tab>
          <van-tab title="火龙果" title-style="font-size: 16PX"
            ><div class="tab-list">
              <img
                src="@/assets/images/baise/火龙果1.png"
                @click="
                  toProduct(
                    'PI163378369590885',
                    'O163377946627264',
                    'PU163378369590946'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/火龙果2.png"
                @click="
                  toProduct(
                    'PI163378369591234',
                    'O163377946852338',
                    'PU163378369591300'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/火龙果3.png"
                @click="
                  toProduct(
                    'PI163378369599887',
                    'O163377952258528',
                    'PU163378369599960'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/火龙果4.png"
                @click="
                  toProduct(
                    'PI163378369592706',
                    'O163377947760152',
                    'PU163378369592767'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/火龙果5.png"
                @click="
                  toProduct(
                    'PI163378369638742',
                    'O163377983940972',
                    'PU163378369638806'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/火龙果6.png"
                @click="
                  toProduct(
                    'PI163378369711784',
                    'O163378032190934',
                    'PU163378369711852'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/火龙果7.png"
                @click="
                  toProduct(
                    'PI163378369712182',
                    'O163378032417504',
                    'PU163378369712248'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/火龙果8.png"
                @click="
                  toProduct(
                    'PI163378369712942',
                    'O163378032867092',
                    'PU163378369713006'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/火龙果9.png"
                @click="
                  toProduct(
                    'PI163378369722967',
                    'O163378038710680',
                    'PU163378369723033'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/火龙果10.png"
                @click="
                  toProduct(
                    'PI163378369721814',
                    'O163378038038709',
                    'PU163378369721879'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/火龙果11.png"
                @click="
                  toProduct(
                    'PI163378369721421',
                    'O163378037816363',
                    'PU163378369721484'
                  )
                "
                class="tab-product"
              /></div
          ></van-tab>
          <van-tab title="西瓜" title-style="font-size: 16PX">
            <div class="tab-list">
              <img
                src="@/assets/images/baise/西瓜1.png"
                @click="
                  toProduct(
                    'PI163378369717261',
                    'O163378035336687',
                    'PU163378369717331'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/西瓜2.png"
                @click="
                  toProduct(
                    'PI163378369716419',
                    'O163378034883984',
                    'PU163378369716506'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/西瓜3.png"
                @click="
                  toProduct(
                    'PI163378369715636',
                    'O163378034436472',
                    'PU163378369715700'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/西瓜4.png"
                @click="
                  toProduct(
                    'PI163378369714099',
                    'O163378033538255',
                    'PU163378369714169'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/西瓜5.png"
                @click="
                  toProduct(
                    'PI163378369715250',
                    'O163378034212982',
                    'PU163378369715319'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/西瓜6.png"
                @click="
                  toProduct(
                    'PI163378369720302',
                    'O163378037141723',
                    'PU163378369720365'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/西瓜7.png"
                @click="
                  toProduct(
                    'PI163378369718766',
                    'O163378036239740',
                    'PU163378369718838'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/西瓜8.png"
                @click="
                  toProduct(
                    'PI163378369716880',
                    'O163378035111146',
                    'PU163378369716944'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/西瓜9.png"
                @click="
                  toProduct(
                    'PI163378369704880',
                    'O163378028143819',
                    'PU163378369704950'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/西瓜10.png"
                @click="
                  toProduct(
                    'PI163378369718030',
                    'O163378035787477',
                    'PU163378369718094'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/西瓜11.png"
                @click="
                  toProduct(
                    'PI163378369719177',
                    'O163378036465069',
                    'PU163378369719244'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/西瓜12.png"
                @click="
                  toProduct(
                    'PI163378369714493',
                    'O163378033761599',
                    'PU163378369714558'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/西瓜13.png"
                @click="
                  toProduct(
                    'PI163378369706815',
                    'O163378029268514',
                    'PU163378369706878'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/西瓜14.png"
                @click="
                  toProduct(
                    'PI163378369714868',
                    'O163378033989236',
                    'PU163378369714935'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/西瓜15.png"
                @click="
                  toProduct(
                    'PI163378369715636',
                    'O163378034436472',
                    'PU163378369715700'
                  )
                "
                class="tab-product"
              />
            </div>
          </van-tab>
          <van-tab title="百香果" title-style="font-size: 16PX">
            <div class="tab-list">
              <img
                src="@/assets/images/baise/百香果1.png"
                @click="
                  toProduct(
                    'PI163378369723731',
                    'O163378039160006',
                    'PU163378369723796'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/百香果2.png"
                @click="
                  toProduct(
                    'PI163378369712569',
                    'O163378032640362',
                    'PU163378369712634'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/百香果3.png"
                @click="
                  toProduct(
                    'PI163378369711408',
                    'O163378031960461',
                    'PU163378369711474'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/百香果4.png"
                @click="
                  toProduct(
                    'PI163378369615273',
                    'O163377964071677',
                    'PU163378369615337'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/百香果5.png"
                @click="
                  toProduct(
                    'PI163378369610720',
                    'O163377960428400',
                    'PU163378369610785'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/百香果6.png"
                @click="
                  toProduct(
                    'PI163378369636836',
                    'O163377982586709',
                    'PU163378369636899'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/百香果7.png"
                @click="
                  toProduct(
                    'PI163378369608861',
                    'O163377958380267',
                    'PU163378369608922'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/百香果8.png"
                @click="
                  toProduct(
                    'PI163378369470399',
                    'O163377833150802',
                    'PU163378369470462'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/百香果9.png"
                @click="
                  toProduct(
                    'PI163378369470399',
                    'O163377833150802',
                    'PU163378369470462'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/百香果10.png"
                @click="
                  toProduct(
                    'PI163672163492838423012',
                    'O163377977193619',
                    'PU163672163493193226927'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/百香果11.png"
                @click="
                  toProduct(
                    'PI163672176427838350458',
                    'O163378038935093',
                    'PU163672176428190760786'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/百香果12.png"
                @click="
                  toProduct(
                    'PI163672186455038949862',
                    'O163377984168536',
                    'PU163672186455398860925'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/百香果13.png"
                @click="
                  toProduct(
                    'PI163672198483688829828',
                    'O163378033538255',
                    'PU163672198484037831908'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/百香果14.png"
                @click="
                  toProduct(
                    'PI163672209455910310574',
                    'O163378034212982',
                    'PU163672209456266471980'
                  )
                "
                class="tab-product"
              />
              <img
                src="@/assets/images/baise/百香果15.png"
                @click="
                  toProduct(
                    'PI163672220555844122549',
                    'O163377743952832',
                    'PU163672220556213934796'
                  )
                "
                class="tab-product"
              />
            </div>
          </van-tab>
        </van-tabs>

        <div class="avtive_footer">
          <img src="@/assets/images/footer.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      active: 0,
      rankActive: 0,
      envLoading: true
    }
  },
  created () {
    document.addEventListener('UniAppJSBridgeReady', () => {
      this.envLoading = false
      console.error(this.envLoading, 'this.envLoading')
    })
  },
  methods: {
    toBack () {
      if (!this.envLoading) {
        window.uni.navigateBack()
      } else {
        this.$toast('环境初始化中，请稍后')
      }
    },
    toProduct (productId, orgId, unitId) {
      if (!this.envLoading) {
        window.uni.navigateTo({
          url: `/pages/product/product/publicProductDetail?product_id=${productId}&org_id=${orgId}&unit_id=${unitId}`
        })
      } else {
        this.$toast('环境初始化中，请稍后')
      }
    },
    toStore (orgId) {
      if (!this.envLoading) {
        window.uni.navigateTo({
          url: `/pages/price/store?org_id=${orgId}&power=public`
        })
      } else {
        this.$toast('环境初始化中，请稍后')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  background: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  height: 13.3333vw;
  display: flex;
  align-items: center;
  color: #000000;
  font-size: 4.8vw;
  font-weight: 500;
  z-index: 999;

  .header-tag {
    font-size: 2.6667vw;
    background: #c4c4c4;
    border-radius: 0.5333vw;
    color: #fff;
    padding: 0 0.8vw;
    margin-left: 2.6667vw;
  }

  .header-back {
    background: #E5E5E5;
    height: 5.3333vw;
    width: 8.5333vw;
    border-radius: 0 8vw 8vw 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 2.6667vw;
    padding-right: 1.0667vw;

    img {
      height: 3.7333vw;
    }
  }
}

.content {
  padding-top: 13.3333vw;
  background: #118f51;

  .top {
    padding: 2.1333vw;
    padding-bottom: 0;

    .rank-product {
      margin-bottom: 2.1333vw;
    }

    .time-box {
      position: relative;
      height: 69.3333vw;

      .time-bg {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }

      .time-content {
        background: #fff;
        border-radius: 0.5333vw;
        position: absolute;
        left: 1.0667vw;
        right: 1.0667vw;
        top: 13.3333vw;
        bottom: 1.3333vw;
        z-index: 9;
        display: flex;
        align-items: center;
        overflow: auto;
        padding-left: 2.1333vw;

        .time-product {
          height: 51.2vw;
          width: 34.6667vw;
          margin-right: 2.1333vw;
        }
      }
    }

    .store {
      background: linear-gradient(145deg, #fbf1ef 0%, #f7dcc7 100%);
      border-radius: 2.1333vw;
      margin-top: 3.2vw;

      .store-title {
        padding: 2.6667vw 0;
        padding-left: 2.1333vw;
        font-size: 4.8vw;
        color: #531a02;
      }

      .van-swipe__indicator {
        background: #fff;
      }

      .store-list {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 8vw;

        .store-product {
          width: calc(50% - 2.1333vw);
          height: 58.1333vw;
          margin-right: 2.1333vw;
          margin-bottom: 2.1333vw;
        }
      }
    }
  }

  .tab-box {
    background: #fff;
    border-radius: 4vw 4vw 0 0;
    overflow: hidden;
    margin-top: 2.6667vw;
    background: #f3f4f6;

    .van-tabs__line {
      bottom: 6.4vw;
    }

    .van-tab--active {
      font-weight: bold;
    }

    .tab-list {
      display: flex;
      flex-wrap: wrap;
      padding-left: 2.1333vw;
      margin-top: 1.3333vw;

      .tab-product {
        width: calc(50% - 2.1333vw);
        height: 65.0667vw;
        margin-right: 2.1333vw;
        margin-bottom: 2.6667vw;
      }
    }
  }

  img {
    max-width: 100%;
  }

  .avtive_footer {
    height: 24vw;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 31.2vw;
      height: 10.1333vw;
    }
  }
}
</style>
